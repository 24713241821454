import { StudentCode, ClassroomCode } from './_interface';

export const studentCodeData: StudentCode[] = [
  {
    id: 1,
    code: '11111',
    studentId: 1,
    classroomId: 1, // nullable
    teacherId: 1,
    adminId: 1,
    connected_at: null,
    created_at: '2023-03-31T16:19:58.916Z',
    updated_at: '2023-03-31T16:19:58.916Z',
  },
  {
    id: 2,
    code: '22222',
    studentId: 1,
    classroomId: 1,
    teacherId: 1,
    adminId: 1,
    connected_at: null,
    created_at: '2023-03-31T16:19:58.916Z',
    updated_at: '2023-03-31T16:19:58.916Z',
  },
];

export const classroomCodeData: ClassroomCode[] = [
  {
    id: 1,
    code: 'AAAAA',
    teacherId: 1,
    adminId: 1,
    connected_at: null,
    created_at: '2023-03-31T16:19:58.916Z',
    updated_at: '2023-03-31T16:19:58.916Z',
    classroom: {
      id: 1,
      seq: 1,
      code: 'aaaaa',
      name: '개나리반',
      teacherId: 1,
      adminId: 1,
      created_at: '2023-03-31T16:19:58.916Z',
      updated_at: '2023-03-31T16:19:58.916Z',
      connection_date: null,
    },
  },
  {
    id: 2,
    code: 'BBBBB',
    teacherId: 1,
    adminId: 1,
    connected_at: null,
    created_at: '2023-03-31T16:19:58.916Z',
    updated_at: '2023-03-31T16:19:58.916Z',
    classroom: {
      id: 1,
      seq: 1,
      code: 'aaaaa',
      name: '개나리반',
      teacherId: 1,
      adminId: 1,
      created_at: '2023-03-31T16:19:58.916Z',
      updated_at: '2023-03-31T16:19:58.916Z',
      connection_date: null,
    },
  },
];
