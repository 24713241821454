import React from 'react';
import StudentHeader from './Header';
import StudentList from './List';
import CreateStudentModal from './Modal/createStudent';
import UpdateStudentModal from './Modal/updateStudent';
import { useQuery } from '@tanstack/react-query';
import Admin from '../../../interfaces/admin';
import { loadAdminInfoAPI } from '../../../apis/admin';

const AdminStudent = () => {
  const { data: admin } = useQuery<Admin>(['admin'], loadAdminInfoAPI);

  return (
    <>
      <main className="flex-1">
        <div className="flex flex-col ">
          <StudentHeader admin={admin} />
          <StudentList admin={admin} />
        </div>
      </main>
      <CreateStudentModal admin={admin} />
      <UpdateStudentModal admin={admin} />
    </>
  );
};

export default AdminStudent;
