import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { useRecoilState } from 'recoil';
import { createStudentModalState } from '../../../../utils/recoil/modal';
import Teacher from '../../../../interfaces/teacher';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  loadAllClassroomByMasterAdminAPI,
  loadAllClassroomWithoutStudentBySlaveAdminAPI,
  loadAllTeacherByAdminAPI,
  loadAllTeacherBySlaveAdminAPI,
} from '../../../../apis/admin';
import Admin from '../../../../interfaces/admin';
import Classroom from '../../../../interfaces/classroom';
import { createStudentByAdmin } from '../../../../apis/student';
import { queryClient } from '../../../..';

interface Props {
  admin: Admin;
}
const CreateStudentModal = ({ admin }: Props) => {
  const { data: teachers } = useQuery<Teacher[]>(
    ['teachers'],
    admin && admin.role === 'Master'
      ? loadAllTeacherByAdminAPI
      : loadAllTeacherBySlaveAdminAPI
  );
  const { data: classrooms } = useQuery<Classroom[]>(
    ['classrooms-teachers'],
    admin && admin.role === 'Master'
      ? loadAllClassroomByMasterAdminAPI
      : loadAllClassroomWithoutStudentBySlaveAdminAPI
  );
  const [createStudentModal, setCreateStudentModal] = useRecoilState<boolean>(
    createStudentModalState
  );
  const [count, setCount] = useState(0);
  const [studentName, setStudentName] = useState('');
  const [classroomId, setClassroomId] = useState(0);
  const [teacherId, setTeacherId] = useState('');
  const [selectedClassroom, setSelectedClassroom] = useState('');
  const [selectedTeacher, setSelectedTeacher] = useState('');

  const mutation = useMutation({
    mutationFn: createStudentByAdmin,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['createStudent'] });
      alert('생성 성공');
    },
    onError: (err) => {
      console.error('error', err);
      alert('생성 실패');
    },
  });

  const closeModal = () => {
    setCount(0);
    setClassroomId(0);
    setSelectedClassroom('');
    setTeacherId('');
    setStudentName('');
    setSelectedTeacher('');
    setCreateStudentModal(false);
  };
  const handleSelectClassroom = (e: any) => {
    const selectedClassroom = classrooms?.find(
      (classroom) => classroom.name === e.target.value
    );
    if (selectedClassroom) {
      setClassroomId(selectedClassroom.id);
      setSelectedClassroom(selectedClassroom.name);
    }
    const selectedTeacher = teachers?.find(
      (teacher) => teacher.id === selectedClassroom.teacherId
    );
    if (selectedTeacher) {
      setSelectedTeacher(selectedTeacher.name);
    }
  };

  const onSubmit = () => {
    closeModal();
    mutation.mutate({ classroomId, num: count, name: studentName });
  };
  return (
    <Transition.Root show={createStudentModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-opacity-75 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  {/* <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                  <CheckIcon
                    className="w-6 h-6 text-green-600"
                    aria-hidden="true"
                  />
                </div> */}
                  {/* <div className="flex flex-col flex-1 p-8"></div> */}
                  <div className="mt-5 text-center sm:mt-5">
                    <Dialog.Title
                      as="h1"
                      className="pb-4 text-2xl font-bold leading-6 text-gray-900"
                    >
                      학생 생성
                    </Dialog.Title>
                    {/* <Dialog.Description
                      as='p'
                      className='pt-4 leading-6 text-gray-700 text-'
                    >
                      1 / 3
                    </Dialog.Description> */}
                    <div className="mt-2">
                      <div className="relative flex mt-2 rounded-md shadow-sm sm:mt-4">
                        <input
                          type="text"
                          id="count"
                          name="count"
                          placeholder="학생번호를 입력해주세요"
                          value={count === 0 ? '' : count}
                          onChange={(e) => setCount(+e.target.value)}
                          autoComplete="off"
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-5 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                        />
                      </div>
                      <div className="relative flex mt-2 rounded-md shadow-sm sm:mt-4">
                        <input
                          type="text"
                          id="studentName"
                          name="studentName"
                          placeholder="학생이름을 입력해주세요"
                          value={studentName}
                          onChange={(e) => setStudentName(e.target.value)}
                          autoComplete="off"
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-5 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                        />
                      </div>
                      <div className="relative flex mt-2 rounded-md shadow-sm sm:mt-4">
                        <select
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-5 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                          onChange={handleSelectClassroom}
                          value={selectedClassroom}
                        >
                          <option
                            value=""
                            disabled
                            className="font-bold disabled:text-gray-500"
                          >
                            학급 선택
                          </option>
                          {classrooms &&
                            classrooms.map((classroom) => (
                              <option
                                key={classroom.name + classroom.id}
                                value={classroom.name}
                              >
                                {classroom.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="relative flex mt-2 rounded-md shadow-sm sm:mt-4">
                        <input
                          type="text"
                          id="teacherId"
                          name="teacherId"
                          placeholder="담당 선생님을 입력해주세요"
                          readOnly={true}
                          value={selectedTeacher}
                          autoComplete="off"
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-5 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10 mb-5 sm:mt-10 sm:mb-5">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-bold text-white bg-red-500 border border-transparent rounded-md shadow-sm hover:bg-gray-200 focus:outline-none h-11 sm:text-sm"
                    onClick={onSubmit}
                  >
                    생성하기
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateStudentModal;
