import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { updateTeacherModalState } from '../../../../utils/recoil/modal';
import { teacherData } from './_data';
import { useQuery } from '@tanstack/react-query';

import {
  loadAllTeacherBySlaveAdminAPI,
  removeTeacherByAdminAPI,
} from '../../../../apis/admin';
import Teacher from '../../../../interfaces/teacher';
import Admin from '../../../../interfaces/admin';
import { updateTeacherState } from '../../../../utils/recoil/data';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../../../..';
import { Dispatch, SetStateAction, useEffect } from 'react';
interface Props {
  admin: Admin;
  setTeacherCount: Dispatch<SetStateAction<number>>;
}

export default function SlaveTable({ admin, setTeacherCount }: Props) {
  const { data: teachers } = useQuery<Teacher[]>(
    ['teachers'],
    loadAllTeacherBySlaveAdminAPI
  );

  useEffect(() => {
    teachers && setTeacherCount(teachers.length);
  }, [teachers]);

  const setUpdateTeacherModal = useSetRecoilState<boolean>(
    updateTeacherModalState
  );
  const setUpdateTeacher = useSetRecoilState<Teacher>(updateTeacherState);
  const deleteMutation = useMutation({
    mutationFn: removeTeacherByAdminAPI,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['deleteTeacher'] });
      alert('삭제 성공');
    },
    onError: (err) => {
      console.error('error', err);
      alert('삭제 실패');
    },
  });

  const handle = {
    update: (e: any) => {
      setUpdateTeacher({
        id: teachers[e].id,
        account: teachers[e].account,
        type: teachers[e].type,
        active: teachers[e].active,
      });
      setUpdateTeacherModal(true);
    },
    delete: (id: any) => {
      deleteMutation.mutate({ id });
    },
  };

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
          >
            <Link to="#" className="inline-flex group">
              No
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              담당 선생님
              <span className="flex-none ml-2 text-gray-900 bg-gray-100 rounded group-hover:bg-gray-200">
                <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              아이디
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              회원 유형
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              담당 관리자
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              생성 시간
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <span className="inline-flex group">
              수정
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </span>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <span className="inline-flex group">
              삭제
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {teachers &&
          teachers.map((teacher, i) => (
            <tr key={teacher.id}>
              <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">
                {i + 1}
              </td>
              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                {teacher.name}
              </td>
              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                {teacher.account}
              </td>
              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                {teacher.type}
              </td>
              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                {teacher.adminId === admin.id && admin.name}
              </td>
              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                {teacher.connection_date.toString().split('T')[0]}
              </td>
              <td className="relative py-4 pl-3 pr-4 text-sm text-left whitespace-nowrap sm:pr-0">
                <button
                  className="text-red-600 hover:text-red-900"
                  onClick={() => handle.update(i)}
                >
                  수정<span className="sr-only">, {teacher.id}</span>
                </button>
              </td>
              <td className="relative py-4 pl-3 pr-4 text-sm text-left whitespace-nowrap sm:pr-0">
                <button
                  className="text-red-600 hover:text-red-900"
                  onClick={() => handle.delete(teacher.id)}
                >
                  삭제<span className="sr-only">, {teacher.id}</span>
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
