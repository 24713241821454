import React from 'react';
import ClassroomHeader from './Header';
import ClassroomList from './List';
import CreateClassroomModal from './Modal/createClassroom';
import UpdateClassroomModal from './Modal/updateClassroom';
import Admin from '../../../interfaces/admin';
import { useQuery } from '@tanstack/react-query';
import {
  loadAdminInfoAPI,
  loadAllTeacherByAdminAPI,
  loadAllTeacherBySlaveAdminAPI,
} from '../../../apis/admin';
import Teacher from '../../../interfaces/teacher';

const AdminClasses = () => {
  const { data: admin } = useQuery<Admin>(['admin'], loadAdminInfoAPI);
  const { data: teachers } = useQuery<Teacher[]>(
    ['teachers'],
    admin && admin.role === 'Master'
      ? loadAllTeacherByAdminAPI
      : loadAllTeacherBySlaveAdminAPI
  );
  return (
    <>
      <main className="flex-1">
        <div className="flex flex-col ">
          <ClassroomHeader admin={admin} />
          <ClassroomList admin={admin} />
        </div>
      </main>
      <CreateClassroomModal teachers={teachers} />
      <UpdateClassroomModal teachers={teachers} />
    </>
  );
};

export default AdminClasses;
