import { atom } from 'recoil';

export const selectedClassTabState = atom<string>({
  key: 'selectedClassTabState',
  default: 'studentList',
});

export const selectedProfileTabState = atom<string>({
  key: 'selectedProfileTabState',
  default: 'info',
});

export const selectedCodesTabState = atom<string>({
  key: 'selectedCodesTabState',
  default: 'studentCodeList',
});
