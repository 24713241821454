import React from 'react';
import Login from '../../containers/Login';

const LoginPage = () => {
  return (
    <>
      <Login />
    </>
  );
};

export default LoginPage;
