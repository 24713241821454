import { Switch } from '@headlessui/react';
import { useRecoilState } from 'recoil';
import { introVideoState } from '../../../utils/recoil/data';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function Toggle({ text }: any) {
  const [IsIntroVideoEnabled, setIsIntroVideoEnabled] =
    useRecoilState(introVideoState);
  return (
    <Switch.Group as='div' className='flex items-center'>
      <Switch.Label as='span' className='mr-3 text-sm'>
        <span className='font-medium text-gray-900'>{text}</span>{' '}
      </Switch.Label>
      <Switch
        checked={IsIntroVideoEnabled}
        onChange={setIsIntroVideoEnabled}
        className={classNames(
          IsIntroVideoEnabled ? 'bg-red-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-red-600 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden='true'
          className={classNames(
            IsIntroVideoEnabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </Switch.Group>
  );
}
