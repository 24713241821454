import axios from 'axios';
import { backUrl } from '../config/config';
import Classroom from '../interfaces/classroom';

axios.defaults.baseURL = backUrl;
axios.defaults.withCredentials = true;

export async function loadClassroomListAPI() {
  return await axios.get('classrooms').then((res) => res.data);
}

export async function loadClassroomInfoAPI(data: Classroom) {
  return await axios.get(`classrooms/${data.id}`).then((res) => res.data);
}

export async function createClassroomAPI(data: Classroom) {
  return await axios.post('classrooms', data).then((res) => res.data);
}

export async function updateClassroomAPI(data: Classroom) {
  return await axios
    .patch(`classrooms/${data.id}`, data)
    .then((res) => res.data);
}

export async function removeClassroomAPI(data: Classroom) {
  return await axios.delete(`classrooms/${data.id}`).then((res) => res.data);
}

export async function createClassroomAdminAPI(data: Classroom) {
  return await axios.post('classrooms/admin', data).then((res) => res.data);
}
export async function updateClassroomAdminAPI(data: Classroom) {
  return await axios.patch(`classrooms/admin`, data).then((res) => res.data);
}
export async function loadClassroomInfoAdminAPI(data: Classroom) {
  return await axios.get(`classrooms/admin/${data.id}`).then((res) => res.data);
}

export async function loadClassroomListSlaveAdminAPI() {
  return await axios.get(`classrooms/admin/slave`).then((res) => res.data);
}

export async function loadClassroomListNoStudentAdminAPI() {
  return await axios
    .get(`classrooms/admin/slave/no-student`)
    .then((res) => res.data);
}
