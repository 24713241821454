import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { useRecoilState } from 'recoil';
import { createAdminLectureModalState } from '../../../../utils/recoil/modal';
import { useQuery, useMutation } from '@tanstack/react-query';
import Classroom from '../../../../interfaces/classroom';
import { queryClient } from '../../../..';
import { createLectureAdminAPI } from '../../../../apis/lecture';
import { loadClassroomInfoAdminAPI } from '../../../../apis/classroom';

interface Props {
  classroomId: number;
}
const CreateLectureModal = ({ classroomId }: Props) => {
  const { data: classroom } = useQuery<Classroom>(['classroom'], () =>
    loadClassroomInfoAdminAPI({ id: classroomId })
  );
  const [createAdminLectureModal, setCreateAdminLectureModal] =
    useRecoilState<boolean>(createAdminLectureModalState);
  const [name, setName] = useState(''); // 학급 이름을 저장하는 state
  const [info, setInfo] = useState(''); // 학급 이름을 저장하는 state

  const mutation = useMutation({
    mutationFn: createLectureAdminAPI,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['createLecture'] });
      alert('생성 성공');
    },
    onError: (err) => {
      console.error('error', err);
      alert('생성 실패');
    },
  });

  const closeModal = () => {
    setName(''); // 학급 이름 state 초기화
    setInfo('');
    setCreateAdminLectureModal(false);
  };

  const onSubmit = () => {
    mutation.mutate({
      name,
      info,
      contentId: 1,
      classroomId: classroom.id,
      teacherId: classroom.teacherId,
    });
    closeModal();
  };

  return (
    <Transition.Root show={createAdminLectureModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-opacity-75 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  {/* <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                  <CheckIcon
                    className="w-6 h-6 text-green-600"
                    aria-hidden="true"
                  />
                </div> */}
                  {/* <div className="flex flex-col flex-1 p-8"></div> */}
                  <div className="mt-5 text-center sm:mt-5">
                    <Dialog.Title
                      as="h1"
                      className="pb-4 text-2xl font-bold leading-6 text-gray-900"
                    >
                      수업 생성
                    </Dialog.Title>
                    {/* <Dialog.Description
                      as='p'
                      className='pt-4 leading-6 text-gray-700 text-'
                    >
                      1 / 3
                    </Dialog.Description> */}
                    <div className="mt-2">
                      <div className="relative flex mt-2 rounded-md shadow-sm sm:mt-4">
                        <span className="w-16 my-auto mr-4 font-bold text-gray-700 text-md">
                          학급:
                        </span>
                        <input
                          type="text"
                          disabled
                          value={classroom && classroom.name}
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-5 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                        />
                      </div>
                      <div className="relative flex mt-2 rounded-md shadow-sm sm:mt-4">
                        <span className="w-16 my-auto mr-4 font-bold text-gray-700 text-md">
                          콘텐츠:
                        </span>
                        <input
                          type="text"
                          disabled
                          value={'콘텐츠: Earth17+'}
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-10 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="relative mt-2 rounded-md shadow-sm sm:mt-4">
                        <input
                          type="text"
                          placeholder="제목을 입력해주세요"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-10 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                        />
                      </div>
                      <div className="relative mt-2 rounded-md shadow-sm sm:mt-4">
                        <textarea
                          value={info}
                          placeholder="내용을 입력해주세요"
                          onChange={(e) => setInfo(e.target.value)}
                          className="block w-full h-32 font-bold border-gray-300 py-1.5 pr-10 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                        />
                      </div>
                      <div className="text-right">
                        <span className="text-xs text-gray-400">
                          {info.length} / 200
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10 mb-5 sm:mt-10 sm:mb-5">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-bold text-white bg-red-500 border border-transparent rounded-md shadow-sm hover:bg-gray-200 focus:outline-none h-11 sm:text-sm"
                    onClick={onSubmit}
                  >
                    생성하기
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateLectureModal;
