import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { createClassModalState } from '../../../utils/recoil/modal';

export default function CodesHeader() {
  const setCreateClassModal = useSetRecoilState<boolean>(createClassModalState);
  const navigate = useNavigate();

  const moveClassesPage = useCallback(() => {
    navigate(`/classes`);
  }, [navigate]);

  const createClassCode = useCallback(() => {
    moveClassesPage();
    setCreateClassModal(true);
  }, [setCreateClassModal, moveClassesPage]);

  return (
    <header className='xl:flex xl:items-center xl:justify-between'>
      <div className='flex-1 min-w-0'>
        <h1 className='text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>
          코드
        </h1>
      </div>
      <div className='flex mt-5 xl:mt-0 xl:ml-4'>
        <button
          type='button'
          className='inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none '
          onClick={createClassCode}
        >
          참가자 초기화
        </button>
      </div>
    </header>
  );
}
