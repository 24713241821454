import React from 'react';
import AdminTeacher from '../../../containers/Admin/Teacher';

export default function AdminTeacherPage() {
  return (
    <>
      <AdminTeacher />
    </>
  );
}
