import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  selectedClassTabState,
  selectedCodesTabState,
} from '../../../utils/recoil/tab';
import { tabData } from './_data';
import { Tab } from './_interface';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export default function CodesTab() {
  const [selected, setSelected] = useRecoilState(selectedCodesTabState);
  const memoizedTabData = useMemo(() => tabData, []);
  const memoizedSelected = useMemo(() => selected, [selected]);

  return (
    <div className='pt-6'>
      <div className='lg:hidden'>
        <label htmlFor='selected-tab' className='sr-only'>
          Select a tab
        </label>
        <select
          id='selected-tab'
          name='selected-tab'
          className='block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm'
          value={selected}
          onChange={(e) => {
            setSelected(e.target.value);
          }}
        >
          {memoizedTabData.map((tab: Tab) => (
            <option key={tab.name} value={tab.type}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className='hidden lg:block'>
        <div className='border-b border-gray-200'>
          <nav className='flex -mb-px space-x-8'>
            {memoizedTabData.map((tab: Tab) => (
              <Link
                key={tab.name}
                to={tab.to}
                className={classNames(
                  tab.type === memoizedSelected
                    ? 'border-red-500 text-red-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                )}
                onClick={() => {
                  setSelected(tab.type);
                }}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
