import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { updateClassroomCodeModalState } from '../../../../utils/recoil/modal';
import { classroomCodeData } from './_data';
import Admin from '../../../../interfaces/admin';
import { useQuery } from '@tanstack/react-query';
import Classroom from '../../../../interfaces/classroom';
import { loadClassroomListNoStudentAdminAPI } from '../../../../apis/classroom';
import { loadAllClassroomBySlaveAdminAPI } from '../../../../apis/admin';

interface Props {
  admin: Admin;
}

export default function Table({ admin }: Props) {
  const { data: classrooms } = useQuery<Classroom[]>(
    ['classrooms'],
    loadAllClassroomBySlaveAdminAPI
  );
  console.log('dfdf', classrooms);
  const setUpdateCodeModal = useSetRecoilState<boolean>(
    updateClassroomCodeModalState
  );

  const [selected, setSelected] = useState([]);

  const checkbox = useRef<HTMLInputElement | null>(null);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  const toggleAll = () => {
    setSelected(checked || indeterminate ? [] : classroomCodeData);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
          >
            <input
              type="checkbox"
              className="w-4 h-4 ml-1 text-red-600 border-gray-300 rounded left-6 top-1/2 focus:ring-red-500 sm:left-4"
              ref={checkbox}
              checked={checked}
              onChange={toggleAll}
            />
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              수업 코드
              <span className="flex-none ml-2 text-gray-900 bg-gray-100 rounded group-hover:bg-gray-200">
                <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              학급 이름
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              소속 학생 수
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              담당 선생님
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              담당 관리자
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              생성 시간
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              진행상태
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <span className="inline-flex group">
              수정
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </span>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <span className="inline-flex group">
              삭제
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {classrooms &&
          classrooms.map((classroom) => {
            return classroom.lectures.map((classroomCode, index) => (
              <tr key={classroomCode.id}>
                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">
                  <input
                    type="checkbox"
                    className="w-4 h-4 ml-1 text-red-600 border-gray-300 rounded left-6 top-1/2 focus:ring-red-500 sm:left-4"
                    value={classroomCode.id}
                    checked={selected.includes(classroomCode)}
                    onChange={(e) =>
                      setSelected(
                        e.target.checked
                          ? [...selected, classroomCode]
                          : selected.filter((p) => p !== classroomCode)
                      )
                    }
                  />
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {classroomCode.code}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {classroom.name}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {/* {classroomCode.classroom} */}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {classroom.teacher.name}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {admin && admin.name}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {classroomCode.createdAt.toString().split('T')[0]}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {classroomCode.status}
                </td>
                <td className="relative py-4 pl-3 pr-4 text-sm text-left whitespace-nowrap sm:pr-0">
                  <button
                    className="text-red-600 hover:text-red-900"
                    onClick={() => setUpdateCodeModal(true)}
                  >
                    수정<span className="sr-only">, {classroomCode.id}</span>
                  </button>
                </td>
                <td className="relative py-4 pl-3 pr-4 text-sm text-left whitespace-nowrap sm:pr-0">
                  <button className="text-red-600 hover:text-red-900">
                    삭제<span className="sr-only">, {classroomCode.id}</span>
                  </button>
                </td>
              </tr>
            ));
          })}
      </tbody>
    </table>
  );
}
