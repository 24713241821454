import {  useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import ProfileHeader from './Header';
import ProfileList from './List';
import ProfileTab from './Tab';
import { useQuery } from '@tanstack/react-query';
import { selectedProfileTabState } from '../../../utils/recoil/tab';
import Admin from '../../../interfaces/admin';
import { loadAdminInfoAPI } from '../../../apis/admin';

export default function AdminProfile() {
  const selected = useRecoilValue<string>(selectedProfileTabState);
  const { data: admin } = useQuery<Admin>(['admin'], loadAdminInfoAPI);

  const renderList = useMemo(() => {
    switch (selected) {
      case 'info':
        return <ProfileList admin={admin} />;
      default:
        return <></>;
    }
  }, [selected]);

  return (
    <>
      <div className="flex h-full">
        <div className="flex flex-col flex-1 min-w-0 overflow-hidden">
          <div className="relative z-0 flex flex-1 overflow-hidden">
            <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
              <article>
                <ProfileHeader admin={admin}/>
                <ProfileTab />
                {renderList}
              </article>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
