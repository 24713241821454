import Classroom from '../../../interfaces/classroom';
import { Student } from '../StudentList/_interface';

export enum LectureStatus {
  before = 'Before',
  ing = 'Ing',
  after = 'After',
}

export interface Lecture {
  id?: number;
  seq?: number;
  code?: string;
  status?: LectureStatus;
  name?: string;
  info?: string;
  intro?: string;
  content?: any;
  classroomId?: number;
  contentId?: number;
  createdAt?: string;
  classroom?: Classroom;
  students?: Student[];
  teacherId?: number;
  startedAt?: string;
  finishedAt?: string;
}

export interface LectureProps {
  lecture: Lecture;
}
