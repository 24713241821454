import { useQuery } from '@tanstack/react-query';
import StudentCard from './StudentCard';
import { studentsData } from './_data';
import { Student } from './_interface';
import { loadClassroomInfoAPI } from '../../../apis/classroom';

interface Props {
  students: Student[];
}

const StudentList = ({ students }: Props) => {
  
  return (
    <div className="py-6 ">
      <ul className="grid grid-cols-1 gap-2 xs:gap-4 xs:grid-cols-cardSize126 xs:auto-rows-cardHeight168">
        {students.map((student: Student) => (
          <li
            key={student.id}
            className="flex flex-col col-span-1 text-center bg-white divide-y divide-gray-200 shadow rounded-xl xs:text-left xs:rounded-3xl"
          >
            <StudentCard student={student} />
          </li>
        ))}
      </ul>
    </div>
  );
};
export default StudentList;
