import { atom } from 'recoil';
import Admin from '../../../interfaces/admin';
import { Role } from '../../../interfaces/admin';
import Teacher, { TeacherType } from '../../../interfaces/teacher';
import Classroom from '../../../interfaces/classroom';
import {
  Lecture,
  LectureStatus,
} from '../../../containers/ClassDetail/LectureList/_interface';
import { Student } from '../../../containers/Admin/Student/List/_interface';

export const updateAdminState = atom<Admin>({
  key: 'updateAdminState',
  default: {
    id: 0,
    account: '',
    password: '',
    role: Role.Slave,
    active: false,
  },
});

export const updateTeacherState = atom<Teacher>({
  key: 'updateTeacherState',
  default: {
    id: 0,
    name: '',
    account: '',
    password: '',
    type: TeacherType.School,
    active: false,
  },
});

export const updateClassroomState = atom<Classroom>({
  key: 'updateClassroomState',
  default: {
    id: 0,
    name: '',
  },
});

export const updateStudentState = atom<Student>({
  key: 'updateStudentState',
  default: {
    id: 0,
    name: '',
    num: 0,
  },
});

export const updateLectureState = atom<Lecture>({
  key: 'updateLectureState',
  default: {
    id: 0,
    name: '',
    info: '',
    status: LectureStatus.before,
  },
});

export const joinLectureCodeState = atom<string>({
  key: 'joinLectureCodeState',
  default: 'ABCDE',
});

export const joinLectureLinkState = atom<string>({
  key: 'joinLectureLinkState',
  default: 'http://localhost:3001',
});
