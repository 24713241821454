import Classroom from './classroom';

export enum TeacherType {
  School = 'School',
  Freelancer = 'Freelancer',
}

enum License {
  Basic = 'Basic',
  Pro = 'Pro',
}

export default interface Teacher {
  id?: number;
  account?: string;
  password?: string;
  name?: string;
  team?: string;
  phone?: string;
  type?: TeacherType;
  classroom?: Classroom;
  license?: License;
  connection_date?: Date;
  active?: boolean;
  adminId?: number;
}
