export enum Role {
  Master = 'Master',
  Slave = 'Slave',
}

export default interface Admin {
  id?: number;
  account?: string;
  password?: string;
  name?: string;
  team?: string;
  phone?: string;
  role?: Role;
  connection_date?: Date;
  active?: boolean;
}
