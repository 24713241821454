import { atom } from 'recoil';

export const createClassModalState = atom<boolean>({
  key: 'createClassModalState',
  default: false,
});
export const joinClassModalState = atom<boolean>({
  key: 'joinClassModalState',
  default: false,
});
export const createLectureModalState = atom<boolean>({
  key: 'createLectureModalState',
  default: false,
});
export const createAdminModalState = atom<boolean>({
  key: 'createAdminModalState',
  default: false,
});
export const updateAdminModalState = atom<boolean>({
  key: 'updateAdminModalState',
  default: false,
});
export const createTeacherModalState = atom<boolean>({
  key: 'createTeacherModalState',
  default: false,
});
export const updateClassroomModalState = atom<boolean>({
  key: 'updateClassroomModalState',
  default: false,
});
export const updateClassroomAdminModalState = atom<boolean>({
  key: 'updateClassroomAdminModalState',
  default: false,
});
export const createStudentModalState = atom<boolean>({
  key: 'createStudentModalState',
  default: false,
});
export const updateTeacherModalState = atom<boolean>({
  key: 'updateTeacherModalState',
  default: false,
});
export const createClassroomModalState = atom<boolean>({
  key: 'createClassroomModalState',
  default: false,
});
export const createClassroomAdminModalState = atom<boolean>({
  key: 'createClassroomAdminModalState',
  default: false,
});
export const updateStudentModalState = atom<boolean>({
  key: 'updateStudentModalState',
  default: false,
});
export const createAdminLectureModalState = atom<boolean>({
  key: 'createAdminLectureModalState',
  default: false,
});
export const updateAdminLectureModalState = atom<boolean>({
  key: 'updateAdminLectureModalState',
  default: false,
});
export const createClassroomCodeModalState = atom<boolean>({
  key: 'createClassroomCodeModalState',
  default: false,
});
export const createStudentCodeModalState = atom<boolean>({
  key: 'createStudentCodeModalState',
  default: false,
});
export const updateClassroomCodeModalState = atom<boolean>({
  key: 'updateClassroomCodeModalState',
  default: false,
});
export const updateStudentCodeModalState = atom<boolean>({
  key: 'updateStudentCodeModalState',
  default: false,
});

export const updateLectureModalState = atom<boolean>({
  key: 'updateLectureModalState',
  default: false,
});

export const updateContentModalState = atom<boolean>({
  key: 'updateContentModalState',
  default: false,
});

export const readLectureCodeModalState = atom<boolean>({
  key: 'readLectureCodeModalState',
  default: false,
});

export const joinLectureModalState = atom<boolean>({
  key: 'joinLectureModalState',
  default: false,
});
export const joinLectureLinkModalState = atom<boolean>({
  key: 'joinLectureLinkModalState',
  default: false,
});

export const lectureStatusState = atom({
  key: 'lectureStatusState',
  default: false,
});

// export const quizListState = atom<boolean>({
//   key: 'quizListState',
//   default: false,
// });

// export const messageState = atom<boolean>({
//   key: 'messageState',
//   default: true,
// });

// export const newMessageState = atom<boolean>({
//   key: 'newMessageState',
//   default: true,
// });

// export const leaderboardState = atom<boolean>({
//   key: 'leaderboardState',
//   default: false,
// });

// export const settingState = atom<boolean>({
//   key: 'settingState',
//   default: false,
// });

// export const mvpState = atom<boolean>({
//   key: 'mvpState',
//   default: false,
// });

// export const mapState = atom<boolean>({
//   key: 'mapState',
//   default: false,
// });

// export const rebootState= atom<boolean>({
//   key: 'rebootState',
//   default: false,
// });

// export const missionEndState= atom<boolean>({
//   key: 'missionEndState',
//   default: false,
// });

// export const gameoverState= atom<boolean>({
//   key: 'gameoverState',
//   default: false,
// });

// export const guardianCodeState= atom<boolean>({
//   key: 'guardianCodeState',
//   default: false,
// });
