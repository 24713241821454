import { useNavigate } from 'react-router-dom';
import ClassroomCard from './ClassCard';
import { useQuery } from '@tanstack/react-query';
import { loadClassroomListAPI } from '../../../apis/classroom';
import Classroom from '../../../interfaces/classroom';

const ClassList = () => {
  const navigate = useNavigate();
  const { data: classrooms } = useQuery(['classrooms'], loadClassroomListAPI);
  const movePage = (e: any) => {
    navigate(`/classes/${e}`);
  };
  return (
    <div className='pt-10'>
      <ul className='grid grid-cols-1 gap-6 cursor-pointer xs:grid-cols-cardSize220 auto-rows-cardHeight280'>
        {classrooms &&
          classrooms.map((classroom: Classroom, i: number) => (
            <li
              key={i}
              onClick={() => movePage(classroom.id)}
              className='flex flex-col col-span-1 text-center xs:text-left '
            >
              <ClassroomCard classData={classroom} />
            </li>
          ))}
      </ul>
    </div>
  );
};
export default ClassList;
