import Admin from '../../../../interfaces/admin';
import { Lecture } from '../../../ClassDetail/LectureList/_interface';
import Table from './table';

interface Props {
  admin: Admin;
  lectures: Lecture[];
  classroomId: number;
}

export default function LectureList({ admin, lectures, classroomId }: Props) {
  return (
    <div className="flex flex-col">
      <div className="self-center w-full py-10">
        <div className="mt-6 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="w-full space-y-10 overflow-x-auto">
              <fieldset>
                <legend className="text-sm font-semibold leading-6 text-gray-900">
                  수업 관리
                </legend>
                <p className="mt-2 text-sm text-gray-700">
                  수업을 관리하는 화면입니다.
                </p>
                <div className="mt-6 space-y-4">
                  <Table
                    admin={admin}
                    lectures={lectures}
                    classroomId={classroomId}
                  />
                  {/* 리스트 끝 */}
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
