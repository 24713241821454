import React from 'react';
import Classroom from '../../../interfaces/classroom';
import Teacher from '../../../interfaces/teacher';
import { teacherDataState } from '../../../utils/recoil/data';
import { useRecoilValue } from 'recoil';

interface Props {
  classData: Classroom;
}

const ClassCard = ({ classData }: Props) => {
  const { name: teacherName } = useRecoilValue<Teacher>(teacherDataState);
  return (
    <div className='flex flex-col flex-1 p-8 bg-white shadow rounded-3xl'>
      <img
        className='flex-shrink-0 w-20 h-20 mx-auto rounded-full xs:mx-0'
        src={
          'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'
        }
        alt='class-img'
      />
      <h3 className='mt-6 text-xl font-bold text-gray-900'>
        학급{classData && classData.id + 1}
      </h3>
      <h3 className='text-xl font-bold text-gray-900'>
        {classData && classData.name}
      </h3>
      <dl className='flex flex-col justify-between flex-grow mt-1'>
        <dt className='sr-only'>Teacher Name</dt>
        <dd className='mt-2 text-base text-gray-500'>{teacherName} 선생님</dd>
        <dt className='sr-only'>Students Number</dt>
        <dd>
          <span className='px-2 py-1 text-xs font-medium text-green-800 bg-green-100 rounded-full'>
            등록된 학생수 {classData && classData.students.length}명
          </span>
        </dd>
      </dl>
    </div>
  );
};
export default ClassCard;
