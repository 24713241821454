import Admin from '../../../../interfaces/admin';
import { profile } from './_data';
import { Fields } from './_interface';

interface Props {
  admin: Admin;
}

const ProfileList = ({ admin }: Props) => {
  // const { fields } = profile;
  // const translateFieldName = (fieldName: string) => {
  //   switch (fieldName) {
  //     case 'name':
  //       return '이름';
  //     case 'phone':
  //       return '휴대폰번호';
  //     case 'email':
  //       return 'Email';
  //     case 'phone_number':
  //       return '유선번호';
  //     case 'area':
  //       return '지역';
  //     case 'affiliation':
  //       return '소속';
  //     case 'subject':
  //       return '담당교과목';
  //     case 'membership_type':
  //       return '회원유형';
  //     case 'registration_date':
  //       return '가입일';
  //     default:
  //       return fieldName;
  //   }
  // };

  return (
    <div className="max-w-5xl px-4 mx-auto mt-6 sm:px-6 lg:px-8">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        {/* {admin && Object.entries(admin).map(([fieldName, fieldValue]: string[]) => (
          <div key={fieldName} className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {translateFieldName(fieldName)}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">{fieldValue}</dd>
          </div>
        ))} */}
        {admin && (
          <>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">이름</dt>
              <dd className="mt-1 text-sm text-gray-900">{admin.name}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">아이디</dt>
              <dd className="mt-1 text-sm text-gray-900">{admin.account}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">휴대폰번호</dt>
              <dd className="mt-1 text-sm text-gray-900">{admin.phone}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">권한</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {admin.role === 'Master' ? '마스터관리자' : '일반관리자'}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">활성화여부</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {admin.active ? 'YES' : 'NO'}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">생성일</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {admin.connection_date.toString().split('T')[0]}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">팀</dt>
              <dd className="mt-1 text-sm text-gray-900">{admin.team}</dd>
            </div>
          </>
        )}
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">About</dt>
          <dd
            className="mt-1 space-y-5 text-sm text-gray-900 max-w-prose"
            dangerouslySetInnerHTML={{ __html: profile.about }}
          />
        </div>
      </dl>
    </div>
  );
};
export default ProfileList;
