import { ClassCodeData } from './_interface';

export const classCodeData: ClassCodeData[] = [
  {
    id: 1,
    code: 'aaaaa',
    className: '1학년 3반',
    teacher: '김선생',
    createdAt: '2023.01.01',
    joinNum: 30,
  },
  {
    id: 2,
    code: 'bbbbb',
    className: '1학년 3반',
    teacher: '나선생',
    createdAt: '2023.01.01',
    joinNum: 30,
  },
];
