import Teacher from '../../../interfaces/teacher';
import { profile } from './_data';
import { Fields } from './_interface';

interface Props {
  teacher: Teacher;
}

const ProfileList = ({ teacher }: Props) => {
  const { fields } = profile;
  const translateFieldName = (fieldName: string) => {
    switch (fieldName) {
      case 'name':
        return '이름';
      case 'phone':
        return '휴대폰번호';
      case 'email':
        return 'Email';
      case 'phone_number':
        return '유선번호';
      case 'area':
        return '지역';
      case 'affiliation':
        return '소속';
      case 'subject':
        return '담당교과목';
      case 'membership_type':
        return '회원유형';
      case 'registration_date':
        return '가입일';
      default:
        return fieldName;
    }
  };

  return (
    <div className="max-w-5xl px-4 mx-auto mt-6 sm:px-6 lg:px-8">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        {/* {Object.entries(fields).map(([fieldName, fieldValue]: string[]) => (
          <div key={fieldName} className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {translateFieldName(fieldName)}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">{fieldValue}</dd>
          </div>
        ))} */}
        {teacher && (
          <>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">이름</dt>
              <dd className="mt-1 text-sm text-gray-900">{teacher.name}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">아이디</dt>
              <dd className="mt-1 text-sm text-gray-900">{teacher.account}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">휴대폰번호</dt>
              <dd className="mt-1 text-sm text-gray-900">{teacher.phone}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">권한</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {teacher.type === 'School' ? '선생님' : '프리랜서'}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">라이센스</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {teacher.license}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">활성화여부</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {teacher.active ? 'YES' : 'NO'}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">생성일</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {teacher.connection_date.toString().split('T')[0]}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">학교</dt>
              <dd className="mt-1 text-sm text-gray-900">{teacher.team}</dd>
            </div>
          </>
        )}
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">About</dt>
          <dd
            className="mt-1 space-y-5 text-sm text-gray-900 max-w-prose"
            dangerouslySetInnerHTML={{ __html: profile.about }}
          />
        </div>
      </dl>
    </div>
  );
};
export default ProfileList;
