import { useLayoutEffect, useRef, useState } from 'react';
import Pagination from '../../../components/Common/Pagination';
import ResponsivePagination from 'react-responsive-pagination';
import { classCodeData } from './_data';
import './pagination.css'; // see pagination.css examples below

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const ClassCodesList = () => {
  const checkbox = useRef<HTMLInputElement | null>(null);
  const [classCodeChecked, setClassCodeChecked] = useState(false);
  const [selectedClassCode, setSelectedClassCode] = useState([]);
  const [classCodeIndeterminate, setClassCodeIndeterminate] = useState(false);

  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const offset = (currentPage - 1) * limit;

  useLayoutEffect(() => {
    const isIndeterminateClass =
      selectedClassCode.length > 0 &&
      selectedClassCode.length < classCodeData.length;
    setClassCodeChecked(selectedClassCode.length === classCodeData.length);
    setClassCodeIndeterminate(isIndeterminateClass);
    checkbox.current.indeterminate = isIndeterminateClass;
  }, [selectedClassCode]);

  const classCodeToggleAll = () => {
    setSelectedClassCode(
      classCodeChecked || classCodeIndeterminate ? [] : classCodeData
    );
    setClassCodeChecked(!classCodeChecked && !classCodeIndeterminate);
    setClassCodeIndeterminate(false);
  };
  return (
    <div className='px-6 pt-8 pb-2 sm:px-0'>
      <div className='flow-root '>
        <div className='-mx-6 -my-2 overflow-x-auto lg:-mx-8'>
          <div className='inline-block min-w-full align-middle sm:px-6 lg:px-8'>
            <div className='relative'>
              <table className='min-w-full divide-y divide-gray-300 table-fixed'>
                <thead>
                  <tr>
                    <th
                      scope='col'
                      className='relative w-16 px-8 sm:w-12 sm:px-6'
                    >
                      <input
                        type='checkbox'
                        className='absolute w-4 h-4 -mt-2 text-red-600 border-gray-300 rounded left-6 top-1/2 focus:ring-red-500 sm:left-4'
                        ref={checkbox}
                        checked={classCodeChecked}
                        onChange={classCodeToggleAll}
                      />
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pr-3 text-left text-xs sm:text-sm font-semibold text-gray-900 whitespace-nowrap'
                    >
                      코드
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-xs sm:text-sm font-semibold text-gray-900 whitespace-nowrap'
                    >
                      학급
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-xs sm:text-sm font-semibold text-gray-900 whitespace-nowrap'
                    >
                      선생님
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-xs sm:text-sm font-semibold text-gray-900 whitespace-nowrap'
                    >
                      생성일
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-xs sm:text-sm font-semibold text-gray-900 whitespace-nowrap'
                    >
                      등록 인원
                    </th>
                    {/* <th
                                scope='col'
                                className='relative py-3.5 pl-3 pr-6 sm:pr-3'
                              >
                                <span className='sr-only'>초기화</span>
                              </th> */}
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 '>
                  {classCodeData
                    .slice(offset, offset + limit)
                    .map((classCode, i) => (
                      <tr
                        key={i}
                        className={
                          selectedClassCode.includes(classCode)
                            ? 'bg-gray-50'
                            : undefined
                        }
                      >
                        <td className='relative w-16 px-8 sm:w-12 sm:px-6'>
                          {selectedClassCode.includes(classCode) && (
                            <div className='absolute inset-y-0 left-0 w-0.5 bg-red-600' />
                          )}
                          <input
                            type='checkbox'
                            className='absolute w-4 h-4 -mt-2 text-red-600 border-gray-300 rounded left-6 top-1/2 focus:ring-red-500 sm:left-4'
                            value={classCode.className}
                            checked={selectedClassCode.includes(classCode)}
                            onChange={(e) =>
                              setSelectedClassCode(
                                e.target.checked
                                  ? [...selectedClassCode, classCode]
                                  : selectedClassCode.filter(
                                      (p) => p !== classCode
                                    )
                              )
                            }
                          />
                        </td>
                        <td
                          className={classNames(
                            'whitespace-nowrap py-2.5 pr-3 text-xs sm:text-sm font-medium',
                            selectedClassCode.includes(classCode)
                              ? 'text-red-600'
                              : 'text-gray-900'
                          )}
                        >
                          {classCode.code}
                        </td>
                        <td className='px-3 py-2.5 text-xs sm:text-sm text-gray-500 whitespace-nowrap'>
                          {classCode.className}
                        </td>
                        <td className='px-3 py-2.5 text-xs sm:text-sm text-gray-500 whitespace-nowrap'>
                          {classCode.teacher}
                        </td>
                        <td className='px-3 py-2.5 text-xs sm:text-sm text-gray-500 whitespace-nowrap'>
                          {classCode.createdAt}
                        </td>
                        <td className='px-3 py-2.5 text-xs sm:text-sm text-gray-500 whitespace-nowrap'>
                          <span className='inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full'>
                            {classCode.joinNum}명
                          </span>
                        </td>
                        {/* <td className='relative py-2.5 pl-3 pr-6 text-xs sm:text-sm font-medium text-right whitespace-nowrap'>
                                  <button
                                    type='button'
                                    className='inline-flex items-center px-3 py-2 text-xs font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm xs:text-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30'
                                  >
                                    초기화
                                  </button>
                                </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
              {/* <Pagination
                total={classCodeData.length}
                limit={limit}
                page={classCodePage}
                setPage={setClassCodePage}
              /> */}
              {/* <ResponsivePagination
                total={classCodeData.length}
                current={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClassCodesList;
