import React from 'react';
import { profile } from './_data';
import Teacher from '../../../interfaces/teacher';

interface Props {
  teacher: Teacher;
}

export default function ProfileHeader({ teacher }: Props) {
  return (
    <div>
      <div>
        <img
          className="object-cover w-full h-32 lg:h-48"
          src={profile.coverImageUrl}
          alt=""
        />
      </div>
      <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div className="flex">
            <img
              className="w-24 h-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
              src={profile.imageUrl}
              alt=""
            />
          </div>
          <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            {/* <div className="flex-1 min-w-0 mt-6 sm:hidden 2xl:block">
              <h1 className="text-2xl font-bold text-gray-900 truncate">
                {profile.name}
              </h1>
            </div> */}
            {/* <div className='flex flex-col mt-6 space-y-3 justify-stretch sm:flex-row sm:space-y-0 sm:space-x-4'>
            <button
              type='button'
              className='inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
            >
              <EnvelopeIcon
                className='-ml-0.5 h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
              문자
            </button>
            <button
              type='button'
              className='inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
            >
              <PhoneIcon
                className='-ml-0.5 h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
              전화
            </button>
          </div> */}
          </div>
        </div>
        <div className="flex-1 hidden min-w-0 mt-6 sm:block 2xl:hidden">
          <h1 className="text-2xl font-bold text-gray-900 truncate">
            {teacher && teacher.name}
          </h1>
        </div>
      </div>
    </div>
  );
}
