import React from 'react';
import DashBoard from '../../containers/DashBoard';

export default function DashboardPage() {
  return (
    <>
      <DashBoard />
    </>
  );
}
