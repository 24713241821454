import { useState } from 'react';
import Admin from '../../../../interfaces/admin';
import Table from './table';
import SlaveTable from './table-slave';
interface Props {
  admin: Admin;
}

export default function TeacherList({ admin }: Props) {
  const [teacherCount, setTeacherCount] = useState<number>(0);
  return (
    <div className="flex flex-col">
      <div className="self-center w-full py-10">
        <div className="mt-6 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="w-full space-y-10 overflow-x-auto">
              <fieldset>
                <legend className="text-sm font-semibold leading-6 text-gray-900">
                  선생님 계정 관리
                </legend>
                <p className="mt-2 text-sm text-gray-700">
                  선생님 계정을 관리하는 화면입니다.(전체 선생님 {teacherCount}
                  명)
                </p>
                <div className="mt-6 space-y-4">
                  {admin && admin.role === 'Master' ? (
                    <>
                      <Table admin={admin} setTeacherCount={setTeacherCount} />
                    </>
                  ) : (
                    <>
                      <SlaveTable admin={admin} setTeacherCount={setTeacherCount} />
                    </>
                  )}
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
