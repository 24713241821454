import React from 'react';
import AdminStudent from '../../../containers/Admin/Student';

export default function AdminStudentPage() {
  return (
    <>
      <AdminStudent />
    </>
  );
}
