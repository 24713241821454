import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedCodesTabState } from '../../utils/recoil/tab';
import CodesHeader from './Header';
import ClassCodesList from './ClassCodesList';
import StudentCodesList from './StudentCodesList';
import CodesTab from './Tab';

export default function Codes() {
  const selected = useRecoilValue<string>(selectedCodesTabState);

  const renderList = useMemo(() => {
    return selected === 'studentCodeList' ? (
      <StudentCodesList />
    ) : (
      <ClassCodesList />
    );
  }, [selected]);

  return (
    <>
      <main className='flex-1'>
        <div className='flex flex-col '>
          <CodesHeader />
          <CodesTab />
          {renderList}
        </div>
      </main>
    </>
  );
}
