import axios from 'axios';
import { backUrl } from '../config/config';
import Teacher from '../interfaces/teacher';

axios.defaults.baseURL = backUrl;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['teacher-token'] = sessionStorage.getItem('teacherAuth');
export async function loadTeacherInfoAPI() {
  const token = sessionStorage.getItem('teacherAuth');
  axios.defaults.headers.common['teacher-token'] = token;
  return await axios.get('teachers').then((res) => res.data);
}

export async function createTeacherAPI(data: Teacher) {
  return await axios.post('teachers', data).then((res) => res.data);
}

export async function logInTeacherAPI(data: Teacher) {
  return await axios.post('teachers/login', data).then((res) => res.data);
}

export async function updateTeacherAPI(data: Teacher) {
  return await axios.patch(`teachers`, data).then((res) => res.data);
}

export async function removeTeacherAPI() {
  return await axios.delete('teachers').then((res) => res.data);
}

export async function logoutAdminAPI() {
  return await axios.post(`teachers/logout`).then((res) => res.data);
}
