import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthLayout from '../components/Layouts/Auth';
import MainLayout from '../components/Layouts/Main';
import MainLayout2 from '../components/Layouts/Main2';
import CodesPage from './codes';
import ClassDetailPage from './classDetail';
import ClassesPage from './classes';
import ProfilePage from './profile';
import LoginPage from './login';
import SetUpPage from './setUp';
import LectureLayout from '../components/Layouts/Lecture';
import StatusPage from './status';
import LiveFeedPage from './liveFeed';
import ResultPage from './result';
import DashboardPage from './dashBoard';
import AccountPage from './admin/account';
import TeacherPage from './admin/teacher';
import StudentPage from './admin/student';
import StudentCodePage from './admin/studentCode';
import ClassCodePage from './admin/classCode';
import AdminClassesPage from './admin/classes';
import AdminAccountPage from './admin/account';
import AdminTeacherPage from './admin/teacher';
import AdminStudentPage from './admin/student';
import AdminClassCodePage from './admin/classCode';
import AdminStudentCodePage from './admin/studentCode';
import AdminPage from './admin';
import AdminProfile from '../containers/Admin/Profile';
import AdminLecturePage from './admin/lecture';

/** App 라우터 */
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout2 />}>
          <Route index element={<ClassesPage />} />
          {/* 학급 목록 페이지 */}
          <Route path="/classes/" element={<ClassesPage />} />
          {/* 학급 디테일 페이지 */}
          <Route path="/classes/:id" element={<ClassDetailPage />} />
          {/* 프로필 디테일 페이지 */}
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/codes" element={<CodesPage />} />
          {/* 어드민의 관리 페이지 */}
          <Route path="/admin/account" element={<AdminAccountPage />} />
          <Route path="/admin/teacher" element={<AdminTeacherPage />} />
          <Route path="/admin/classroom" element={<AdminClassesPage />} />
          <Route path="/admin/student" element={<AdminStudentPage />} />
          <Route path="/admin/classCode" element={<AdminClassCodePage />} />
          <Route path="/admin/studentCode" element={<AdminStudentCodePage />} />
          <Route path="/admin/classroom/:id" element={<AdminLecturePage />} />
          <Route path="/admin/profile" element={<AdminProfile />} />
        </Route>
        <Route path="/lectures/:id" element={<LectureLayout />}>
          {/* 수업 디테일 페이지 */}
          <Route index element={<DashboardPage />} />
          <Route path="/lectures/:id/dashboard" element={<DashboardPage />} />
          <Route path="/lectures/:id/setup" element={<SetUpPage />} />
          <Route path="/lectures/:id/status" element={<StatusPage />} />
          <Route path="/lectures/:id/livefeed" element={<LiveFeedPage />} />
          <Route path="/lectures/:id/result" element={<ResultPage />} />
        </Route>
        <Route path="/login" element={<AuthLayout />}>
          <Route index element={<LoginPage />} />
        </Route>
        <Route path="/admin" element={<AuthLayout />}>
          <Route index element={<AdminPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

/*
  로그인 > 회원가입 > 로그인완료
    코드목록
    학급목록 > 학급선택 > 학급정보 & 학생목록 & 수업목록
      학급정보 > 학급코드 & 학급명 & 선생님이름
      학생목록(Menu1 / Default) > 학생정보 > 
      수업목록(Menu2) > 수업정보 
    프로필
*/
