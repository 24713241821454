import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Button({ label, text, href }: any) {
  const navigate = useNavigate();
  const moveStudentCodePage = () => {
    navigate(href);
  };

  return (
    <div>
      <label
        htmlFor='finish-round'
        className='block text-sm font-medium leading-6 text-gray-900'
      >
        {label}
      </label>
      <div className='relative mt-2 '>
        <button
          type='button'
          className='px-3 py-2 text-sm font-semibold text-white bg-red-500 rounded-md shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
          onClick={moveStudentCodePage}
        >
          {text}
        </button>
      </div>
    </div>
  );
}
