// import Alert from '../../components/Common/Alert';
// import Button from '../../components/Common/Button';
// import NumberInput from '../../components/Common/Inputs/Number';
// import Toggle from '../../components/Common/Toggle';
import React, { useState } from 'react';
import {
  loadAllClassroomByMasterAdminAPI,
  loadAllClassroomBySlaveAdminAPI,
} from '../../../../apis/admin';
import Admin from '../../../../interfaces/admin';
import Table from './table';
import { useQuery } from '@tanstack/react-query';
import SlaveTable from './table-slave';
interface Props {
  admin: Admin;
}
export default function ClassroomList({ admin }: Props) {

  const [classroomCount, setClassroomCount] = useState<number>(0);
  return (
    <div className="flex flex-col">
      <div className="self-center w-full py-10">
        <div className="mt-6 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="w-full space-y-10 overflow-x-auto">
              <fieldset>
                <legend className="text-sm font-semibold leading-6 text-gray-900">
                  학급 관리
                </legend>
                <p className="mt-2 text-sm text-gray-700">
                  학급을 관리하는 화면입니다. (등록된 학급 수&nbsp;
                  {classroomCount}개)
                </p>
                <div className="mt-6 space-y-4">
                  {/* 리스트를 적으면 됌. */}
                  {admin && admin.role === 'Master' ? (
                    <>
                      <Table admin={admin} setClassroomCount={setClassroomCount} />
                    </>
                  ) : (
                    <>
                      <SlaveTable admin={admin} setClassroomCount={setClassroomCount} />
                    </>
                  )}
                  {/* 리스트 끝 */}
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
