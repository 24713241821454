import React from 'react';
import AdminStudentCode from '../../../containers/Admin/StudentCode';

export default function AdminStudentCodePage() {
  return (
    <>
      <AdminStudentCode />
    </>
  );
}
