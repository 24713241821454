import React from 'react';
import LectureHeader from './Header';
import LectureList from './List';
import CreateLectureModal from './Modal/createLecture';
import UpdateLectureModal from './Modal/updateLecture';
import { useQuery } from '@tanstack/react-query';
import Admin from '../../../interfaces/admin';
import { loadAdminInfoAPI } from '../../../apis/admin';
import { Lecture } from '../../ClassDetail/LectureList/_interface';

interface Props {
  lectures: Lecture[];
  classroomId: number;
}

const AdminLecture = ({ classroomId, lectures }: Props) => {
  const { data: admin } = useQuery<Admin>(['admin'], loadAdminInfoAPI);
  return (
    <>
      <main className="flex-1">
        <div className="flex flex-col ">
          <LectureHeader admin={admin} classroomId={classroomId} />
          <LectureList
            admin={admin}
            lectures={lectures}
            classroomId={classroomId}
          />
        </div>
      </main>
      <CreateLectureModal classroomId={classroomId} />
      <UpdateLectureModal admin={admin} />
    </>
  );
};

export default AdminLecture;
