import React from 'react';
import SetUp from '../../containers/SetUp';

export default function SetUpPage() {
  return (
    <>
      <SetUp />
    </>
  );
}
