import axios from 'axios';
import { backUrl } from '../config/config';
import { Lecture } from '../containers/ClassDetail/LectureList/_interface';

axios.defaults.baseURL = backUrl;
axios.defaults.withCredentials = true;

export async function loadLectureListAPI() {
  return await axios.get('lectures').then((res) => res.data);
}
export async function loadLectureListWithContentAPI(classroomId: number) {
  return await axios
    .get(`lectures/teacher/list/${classroomId}`)
    .then((res) => res.data);
}

export async function loadLectureInfoAPI(data: Lecture) {
  return await axios.get(`lectures/teacher/${data.id}`).then((res) => res.data);
}

export async function loadLectureStagesAPI(data: Lecture) {
  return await axios.get(`lectures/stages/${data.id}`).then((res) => res.data);
}

export async function createLectureAPI(data: Lecture) {
  return await axios.post('lectures/teacher', data).then((res) => res.data);
}

export async function updateLectureAPI(data: Lecture) {
  return await axios.patch(`lectures/${data.id}`, data).then((res) => res.data);
}

export async function updateContentLectureAPI(data: any) {
  return await axios
    .patch(`lectures/teacher/content/${data.id}`, data)
    .then((res) => res.data);
}

export async function findContentLectureAPI(data: any) {
  return await axios
    .get(`lectures/teacher/content/${data}`, data)
    .then((res) => res.data);
}

export async function removeLectureAPI(data: Lecture) {
  return await axios.delete(`lectures/${data.id}`).then((res) => res.data);
}

export async function loadLectureListByAdminAPI(classroomId: number) {
  return await axios
    .get(`lectures/admin/list/${classroomId}`)
    .then((res) => res.data);
}

export async function createLectureAdminAPI(data: Lecture) {
  return await axios.post('lectures/admin', data).then((res) => res.data);
}

export async function updateLectureAdminAPI(data: Lecture) {
  return await axios.patch(`lectures/admin`, data).then((res) => res.data);
}

export async function removeLectureAdminAPI(data: Lecture) {
  return await axios
    .delete(`lectures/admin/${data.id}`)
    .then((res) => res.data);
}
