import Table from './table';

export default function CodeList() {
  return (
    <div className='flex flex-col'>
      <div className='self-center w-full py-10'>
        <div className='mt-6 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2'>
          <div className='px-4 py-6 sm:p-8'>
            <div className='w-full space-y-10 overflow-x-auto'>
              <fieldset>
                <div className='flex items-center justify-between'>
                  <legend className='text-sm font-semibold leading-6 text-gray-900'>
                    학생 코드 관리
                  </legend>
                  {/* <Button text='선택된 코드 확인' /> */}
                </div>
                <p className='mt-2 text-sm text-gray-700'>
                  학생 코드 정보를 관리하는 화면입니다.
                </p>
                <div className='mt-6 space-y-4'>
                  <Table />
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
