import React from 'react';
import Result from '../../containers/Result';

export default function ResultPage() {
  return (
    <>
      <Result />
    </>
  );
}
