// import Alert from '../../components/Common/Alert';
// import Button from '../../components/Common/Button';
// import NumberInput from '../../components/Common/Inputs/Number';
// import Toggle from '../../components/Common/Toggle';
import { personData } from './_data';
import Info from '../../../components/Common/Alert/info';
import { Link } from 'react-router-dom';
import Feed from '../../LiveFeed/feed';
import { Fragment, useEffect, useState } from 'react';
import Toggle from '../../../components/Common/Toggle';
import Notice from '../../../components/Common/Alert/notice';
import NumberInput from '../../../components/Common/Inputs/Number';
import Button from '../../../components/Common/Button';
import { Lecture } from '../../ClassDetail/LectureList/_interface';
import { socket } from '../../../config/config';
import { useQuery } from '@tanstack/react-query';
import { loadLectureStagesAPI } from '../../../apis/lecture';

// import AccountTable from './table';

const activity = [
  {
    id: 1,
    type: 'image',
    person: { name: '신짱구', to: '#' },
    date: '00월 00일 오후 00:00:00',
    mission: {
      name: '미션이름1',
      question: '이미지 미션 문제 내용이 들어갈 공간입니다.',
      answer: 'https://via.placeholder.com/400x300',
    },
  },
  {
    id: 2,
    type: 'text',
    person: { name: '한유리', to: '#' },
    date: '00월 00일 오후 00:00:00',
    mission: {
      name: '미션이름2',
      question: '텍스트 미션 문제 내용이 들어갈 공간입니다.',
      answer:
        '텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.',
    },
  },
  {
    id: 3,
    type: 'gps',
    person: { name: '김철수', to: '#' },
    date: '00월 00일 오후 00:00:00',
    mission: {
      name: '미션이름3',
      question: 'GPS 미션 문제 내용이 들어갈 공간입니다.',
      answer: 'https://via.placeholder.com/400x300',
    },
  },
  {
    id: 4,
    type: 'image',
    person: { name: '이훈이', to: '#' },
    date: '00월 00일 오후 00:00:00',
    mission: {
      name: '미션이름4',
      question: '이미지 미션 문제 내용이 들어갈 공간입니다.',
      answer: 'https://via.placeholder.com/400x300',
    },
  },
];

interface Props {
  lecture: Lecture;
}

export default function DashBoardList({ lecture }: Props) {
  const [gameStatus, setGameStatus] = useState({});
  const [participants, setParticipants] = useState([]);
  // const [refresh, setRefresh] = useState(Date.now());
  const { data: stages } = useQuery(
    ['stages', lecture?.id],
    () => lecture && loadLectureStagesAPI({ id: lecture.id }),
    { enabled: !!lecture }
  );

  // useEffect(() => {
  //   lecture && socket.emit('joinLecture', lecture.id);

  //   socket.on('gameStatus', (status) => {
  //     setGameStatus(status);
  //   });

  //   socket.on('participants', (participants) => {
  //     const arr: string[] = [];
  //     participants.map((item: any) => {
  //       arr.push(item.split('&&')[0]);
  //     });
  //     setParticipants(arr);
  //   });

  //   socket.on('statusUpdate', ({ clientId, status }) => {
  //     // statusUpdate 이벤트를 받으면, participants 상태를 업데이트
  //     setParticipants((prev) => {
  //       const idx = prev.findIndex((item) => item.split('&&')[0] === clientId);
  //       if (idx !== -1) {
  //         const updated = [...prev];
  //         // status 업데이트 부분 (예: '접속중', '미접속')
  //         updated[idx] = `${clientId}&&${status}`;
  //         return updated;
  //       }
  //       return prev;
  //     });
  //   });

  //   return () => {
  //     socket.off('gameStatus');
  //     socket.off('participants');
  //     socket.off('statusUpdate');
  //     socket.emit('leaveLecture', lecture?.id);
  //     // lecture && socket.emit('leaveLecture', lecture.id);
  //   };
  // }, [lecture]);

  useEffect(() => {
    console.log('lecture updated:', lecture);
    lecture && socket.emit('joinLecture', lecture.id);

    socket.on('gameStatus', (status) => {
      console.log('gameStatus event received:', status);
      setGameStatus(status);
    });

    socket.on('participants', (participants) => {
      console.log('participants event received:', participants); // participants event received: ['HEIBU&&Nou_TpIW0XFk3uQoAAAz'] code && client.id
      const arr: string[] = [];

      participants.forEach((item: any) => {
        arr.push(`${item.split('&&')[0]}&&${item.split('&&')[1]}&&online`);
      });

      setParticipants(arr); // participants: ['HEIBU&&Nou_TpIW0XFk3uQoAAAz&&online']
    });

    socket.on('statusUpdate', ({ clientId, status }) => {
      console.log('statusUpdate event received:', clientId, status);
      // statusUpdate 이벤트를 받으면, participants 상태를 업데이트
      setParticipants((prev) => {
        const idx = prev.findIndex((item) => item.split('&&')[1] === clientId); // 일치하면 Idx값 불일치하면 -1
        if (idx !== -1) {
          // idx가 불일치 하지 않다면
          const updated = [...prev];
          const codeAndClientId =
            prev[idx].split('&&')[0] + '&&' + prev[idx].split('&&')[1];
          updated[idx] = `${codeAndClientId}&&${status}`;
          return updated;
        }
        console.log('프리브:', prev);
        return prev;
      });
      console.log('수업참가자:', participants);
    });

    return () => {
      socket.off('gameStatus');
      socket.off('participants');
      socket.off('statusUpdate');
      lecture && socket.emit('leaveLecture', lecture.id);
    };
  }, [lecture]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setRefresh(Date.now());
  //   }, 2000);

  //   return () => clearTimeout(timer);
  // }, [refresh]);

  return (
    <div className='flex flex-col'>
      {/* <div className='w-full'>
        <div className='mt-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2'>
          <Info
            name={`수업 내용`}
            text={`수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용`}
          />
          <Info
            name={`수업 일시`}
            text={`수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용`}
          />
          <Info
            name={`콘텐츠 정보`}
            text={`수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용수업 내용`}
          />
        </div>
      </div> */}
      <div className='flex gap-4 '>
        <div className='flex flex-col w-1/2'>
          <div className='flex mt-4 overflow-auto bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2'>
            <Info
              name={`총 등록 학생 수: ${
                lecture
                  ? lecture.classroom.students.length >= 10
                    ? lecture.classroom.students.length
                    : '0' + lecture.classroom.students.length
                  : '00'
              }명`}
            />
            <Info
              name={`현재 접속 학생 수: ${
                participants.length >= 10
                  ? participants.length
                  : '0' + participants.length
              }명`}
            />
          </div>
          {/* 접속 현황 */}
          <div>
            <div className='overflow-auto h-[57vh] mt-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2'>
              <div className='px-4 py-6 sm:p-8'>
                <div className='w-full space-y-10'>
                  <div className='space-y-4'>
                    <div className='flex'>
                      <h3 className='flex-1 text-sm font-semibold leading-6 text-gray-900'>
                        접속 현황
                      </h3>
                      <Link
                        to='status'
                        className='text-sm font-medium text-gray-500 hover:text-gray-700'
                      >
                        더보기
                      </Link>
                    </div>
                    {/* 리스트를 적으면 됌. */}
                    <ul className='grid grid-cols-1 gap-2 '>
                      {/* 학생 카드 */}
                      {lecture &&
                        lecture.classroom.students.map((person) => (
                          <li
                            key={person.code}
                            className='col-span-1 bg-white divide-y divide-gray-200 rounded-lg shadow'
                          >
                            <div className='flex items-center justify-between w-full p-4 space-x-6'>
                              <div className='flex-1 truncate'>
                                <div className='flex items-center space-x-3'>
                                  <img
                                    className='w-10 h-10 rounded-full'
                                    src={person.imageUrl}
                                    alt=''
                                  />
                                  <div className='flex flex-col'>
                                    <div className='flex gap-2'>
                                      <span className='text-sm font-medium text-gray-900'>
                                        {person.name}
                                      </span>
                                      <span className='text-sm text-gray-500'>
                                        {person.code}
                                      </span>
                                    </div>
                                    <p className='text-sm font-medium text-gray-900'>
                                      {person.score ? person.score : '00'}점
                                    </p>
                                  </div>
                                  {/* {participants.includes(person.code) ? ( */}
                                  {participants.find(
                                    (participant) =>
                                      participant.split('&&')[0] ===
                                        person.code &&
                                      participant.split('&&')[2] === 'online'
                                  ) ? (
                                    <span className='flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full'>
                                      접속중
                                    </span>
                                  ) : (
                                    <span className='flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-gray-100 rounded-full'>
                                      미접속
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                    {/* 리스트 끝 */}
                  </div>
                </div>
              </div>
            </div>
            {/* 리더보드 */}
            <div className='overflow-auto h-[calc(18vh-1rem)] mt-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2'>
              <div className='px-4 py-6 sm:p-8'>
                <div className='w-full space-y-10'>
                  <div className='space-y-4'>
                    <div className='flex'>
                      <h3 className='flex-1 text-sm font-semibold leading-6 text-gray-900'>
                        리더보드
                      </h3>
                      <Link
                        to='status'
                        className='text-sm font-medium text-gray-500 hover:text-gray-700'
                      >
                        더보기
                      </Link>
                    </div>
                    {/* 리스트를 적으면 됌. */}
                    <ul className='flex flex-wrap gap-2 '>
                      {/* 학생 카드 */}
                      {lecture &&
                        lecture.classroom.students.map((person) => (
                          <li
                            key={person.code}
                            className='col-span-1 bg-white divide-y divide-gray-200 rounded-lg shadow'
                          >
                            <div className='flex items-center justify-between w-full p-4 space-x-6'>
                              <div className='flex-1 truncate'>
                                <div className='flex items-center space-x-3'>
                                  <img
                                    className='w-10 h-10 rounded-full'
                                    src={person.imageUrl}
                                    alt=''
                                  />
                                  <div className='flex flex-col'>
                                    <div className='flex gap-2'>
                                      <span className='text-sm font-medium text-gray-900'>
                                        {person.name}
                                      </span>
                                      <span className='text-sm text-gray-500'>
                                        {person.code}
                                      </span>
                                    </div>
                                    <p className='text-sm font-medium text-gray-900'>
                                      {person.score ? person.score : '00'}점
                                    </p>
                                  </div>
                                  {participants.find(
                                    (participant) =>
                                      participant.split('&&')[0] ===
                                        person.code &&
                                      participant.split('&&')[2] === 'online'
                                  ) ? (
                                    <span className='flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full'>
                                      접속중
                                    </span>
                                  ) : (
                                    <span className='flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-gray-100 rounded-full'>
                                      미접속
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                    {/* 리스트 끝 */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-1/2'>
          {/* 콘텐츠 설정 */}
          {/* <form className='mt-4 overflow-auto h-[29vh] bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2'>
            <div className='px-4 py-6 sm:p-8'>
              <div className='space-y-10'>
                <div>
                  <div className='flex'>
                    <strong className='flex-1 text-sm font-semibold leading-6 text-gray-900'>
                      콘텐츠 설정
                    </strong>
                    <Link
                      to='setup'
                      className='text-sm font-medium text-gray-500 hover:text-gray-700'
                    >
                      더보기
                    </Link>
                  </div>
                  <div className='mt-6 space-y-4'>
                    <div
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <Toggle text={'인트로 스토리 영상'} disabled={true} />
                    </div>
                    {isHovered && (
                      <Notice
                        name={'Notice'}
                        text={
                          '토글 버튼을 활성화하면 초기 인트로 스토리 영상이 재생됩니다.'
                        }
                      />
                    )}
                    <NumberInput
                      name={'게임 종료조건 (몬스터 라운드)'}
                      placeholder={'7'}
                      disabled={true}
                    />
                    <Button
                      label={'코드 초기화'}
                      text={'학생코드로 이동'}
                      href={'/codes'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form> */}
          {/* 라이브피드 */}
          <div className='mt-4 overflow-auto bg-white shadow-sm h-[calc(83.5vh-1rem)] ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2'>
            <div className='px-4 py-6 sm:p-8'>
              <div className='w-full space-y-10 '>
                <div>
                  <div className='flex'>
                    <strong className='flex-1 text-sm font-semibold leading-6 text-gray-900'>
                      라이브 피드
                    </strong>
                    <Link
                      to='livefeed'
                      className='text-sm font-medium text-gray-500 hover:text-gray-700'
                    >
                      더보기
                    </Link>
                  </div>

                  <div className='mt-6 space-y-4'>
                    {/* 리스트를 적으면 됌. */}
                    <div className='flow-root'>
                      <ul role='feed' className='-mb-8'>
                        {stages &&
                          stages.map((feedItem: any, feedItemIdex: number) => {
                            if (feedItem.isSolved) {
                              return (
                                <li key={feedItem.id}>
                                  <div className='relative pb-8'>
                                    {feedItemIdex !== stages.length - 1 ? (
                                      <span
                                        className='absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200'
                                        aria-hidden='true'
                                      />
                                    ) : null}
                                    <div className='relative flex items-start space-x-3'>
                                      <Feed feedItem={feedItem} />
                                    </div>
                                  </div>
                                </li>
                              );
                            }
                          })}
                      </ul>
                    </div>
                    {/* 리스트 끝 */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
