import {
  loadLectureListAPI,
  loadLectureListWithContentAPI,
} from '../../../apis/lecture';
import LectureCard from './LecutreCard';
import { lecturesData } from './_data';
import { Lecture } from './_interface';
import { useQuery } from '@tanstack/react-query';

interface Props {
  classroomId: number;
}

const LectureList = ({ classroomId }: Props) => {
  const { data: lectures } = useQuery<Lecture[]>(['lectures'], () =>
    loadLectureListWithContentAPI(classroomId)
  );
  return (
    <div className="py-6 ">
      <ul className="grid max-w-5xl grid-cols-1 gap-4 auto-rows-auto sm:auto-rows-cardHeight200 xl:auto-rows-cardHeight240 ">
        {lectures &&
          lectures.map((lecture: Lecture) => (
            <li
              key={lecture.id}
              className="flex flex-row row-span-1 gap-4 text-center xs:text-left rounded-3xl"
            >
              <div className="flex flex-col">
                <div className="flex flex-col flex-1 sm:bg-inherit rounded-2xl">
                  <LectureCard lecture={lecture} />
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};
export default LectureList;
