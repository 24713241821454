import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { updateClassroomAdminModalState } from '../../../../utils/recoil/modal';
import { updateClassroomState } from '../../../../utils/recoil/data';
import Classroom from '../../../../interfaces/classroom';
import { useMutation } from '@tanstack/react-query';
import { updateClassroomAdminAPI } from '../../../../apis/classroom';
import { queryClient } from '../../../..';
import Teacher from '../../../../interfaces/teacher';

interface Props {
  teachers: Teacher[];
}
const UpdateClassroomModal = ({ teachers }: Props) => {
  const [updateClassroomModal, setUpdateClassroomModal] =
    useRecoilState<boolean>(updateClassroomAdminModalState);
  const [updateClassroom, setUpdateClassroom] =
    useRecoilState<Classroom>(updateClassroomState);
  const [name, setName] = useState('');
  const [teacherId, setTeacherId] = useState(0);
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [first, setFirst] = useState<boolean>(false);
  useEffect(() => {
    if (
      !first &&
      updateClassroom.name !== '' &&
      updateClassroom.name !== name
    ) {
      setFirst(true);
    }
  }, [first, updateClassroom, name]);

  useEffect(() => {
    if (first) {
      setName(updateClassroom.name);
    }
  }, [first, updateClassroom]);
  const mutation = useMutation({
    mutationFn: updateClassroomAdminAPI,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['updateClassroom'] });
      alert('수정 성공');
    },
    onError: (err) => {
      console.error('error', err);
      alert('수정 실패');
    },
  });
  const closeModal = () => {
    setName(''); // 학급 이름 state 초기화
    setTeacherId(0);
    setUpdateClassroom({ name: '' });
    setUpdateClassroomModal(false);
  };
  const handleInput = (e: any) => {
    setName(e.target.value);
  };

  const handleSelectTeacher = (e: any) => {
    const selectedTeacher = teachers?.find(
      (teacher) => teacher.name === e.target.value
    );
    if (selectedTeacher) {
      setTeacherId(selectedTeacher.id);
      setSelectedTeacher(selectedTeacher.name);
    }
  };
  const onSubmit = () => {
    const confirm = window.confirm('수정하시겠습니까?');

    if (!confirm) return closeModal();
    mutation.mutate({ id: updateClassroom.id, name });
    closeModal();
  };

  return (
    <Transition.Root show={updateClassroomModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-opacity-75 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  {/* <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                  <CheckIcon
                    className="w-6 h-6 text-green-600"
                    aria-hidden="true"
                  />
                </div> */}
                  {/* <div className="flex flex-col flex-1 p-8"></div> */}
                  <div className="mt-5 text-center sm:mt-5">
                    <Dialog.Title
                      as="h1"
                      className="pb-4 text-2xl font-bold leading-6 text-gray-900"
                    >
                      학급 수정
                    </Dialog.Title>
                    {/* <Dialog.Description
                      as='p'
                      className='pt-4 leading-6 text-gray-700 text-'
                    >
                      1 / 3
                    </Dialog.Description> */}
                    <div className="mt-2">
                      <div className="relative flex mt-2 rounded-md shadow-sm sm:mt-4">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="학급 이름을 입력해주세요"
                          value={name}
                          onChange={handleInput}
                          autoComplete="off"
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-5 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                        />
                      </div>
                      <div className="relative flex mt-2 rounded-md shadow-sm sm:mt-4">
                        <input
                          type="text"
                          id="teacher"
                          name="teacher"
                          placeholder="선생님 이름을 입력해주세요"
                          readOnly={true}
                          value={
                            updateClassroom.teacher
                              ? updateClassroom.teacher.name
                              : selectedTeacher
                          }
                          onChange={handleInput}
                          autoComplete="off"
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-5 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10 mb-5 sm:mt-10 sm:mb-5">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-bold text-white bg-red-500 border border-transparent rounded-md shadow-sm hover:bg-gray-200 focus:outline-none h-11 sm:text-sm"
                    onClick={onSubmit}
                  >
                    저장하기
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UpdateClassroomModal;
