import React from 'react';
import { CalendarIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { RocketLaunchIcon, UsersIcon } from '@heroicons/react/24/outline';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  createLectureModalState,
  joinClassModalState,
} from '../../../utils/recoil/modal';
import Classroom from '../../../interfaces/classroom';
import Teacher from '../../../interfaces/teacher';
import {
  classroomDataState,
  teacherDataState,
} from '../../../utils/recoil/data';

export default function ClassDetailHeader() {
  const setJoinClassModal = useSetRecoilState<boolean>(joinClassModalState);
  const { name, students, createdAt } =
    useRecoilValue<Classroom>(classroomDataState);
  const { name: teacherName } = useRecoilValue<Teacher>(teacherDataState);
  const setCreateLectureModal = useSetRecoilState<boolean>(
    createLectureModalState
  );

  return (
    <header className='xl:flex xl:items-center xl:justify-between'>
      <div className='flex-1 min-w-0'>
        <nav className='flex' aria-label='Breadcrumb'>
          <ol className='flex items-center space-x-4'>
            <li>
              <div>
                <Link
                  to='/classes'
                  className='text-sm font-medium text-gray-500 hover:text-gray-700'
                >
                  학급
                </Link>
              </div>
            </li>
            <li>
              <div className='flex items-center'>
                <ChevronRightIcon
                  className='flex-shrink-0 w-5 h-5 text-gray-400'
                  aria-hidden='true'
                />
                {/* <Link
                to='#'
                className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
              >
                Engineering
              </Link> */}
              </div>
            </li>
          </ol>
        </nav>
        <h1 className='mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>
          {name}
        </h1>
        <div className='flex flex-col mt-1 sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8'>
          <div className='flex items-center mt-2 text-sm text-gray-500'>
            <RocketLaunchIcon
              className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400'
              aria-hidden='true'
            />
            {teacherName} 선생님
          </div>
          <div className='flex items-center mt-2 text-sm text-gray-500'>
            <UsersIcon
              className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400'
              aria-hidden='true'
            />
            학생 수 {students?.length}명
          </div>
          <div className='flex items-center mt-2 text-sm text-gray-500'>
            <CalendarIcon
              className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400'
              aria-hidden='true'
            />
            생성일 {createdAt?.toString().split('T')[0]}
          </div>
        </div>
      </div>
      <div className='flex gap-2 mt-5 xl:mt-0 xl:ml-4'>
        {/* <span className='hidden sm:block'> */}
        <button
          type='button'
          className='inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none '
          onClick={() => setJoinClassModal(true)}
        >
          학급 참여하기
        </button>
        <button
          type='button'
          className='inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none '
          onClick={() => setCreateLectureModal(true)}
        >
          수업 생성하기
        </button>
        {/* </span> */}
      </div>
    </header>
  );
}
