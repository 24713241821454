import { StudentCodeData } from './_interface';

export const studentCodeData: StudentCodeData[] = [
  {
    id: 1,
    code: 'AAAAA',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 2,
    code: 'BBBBB',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 3,
    code: 'CCCCC',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 4,
    code: 'DDDDD',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 5,
    code: 'EEEEE',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 6,
    code: 'FFFFF',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 7,
    code: 'GGGGG',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 8,
    code: 'HHHHH',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 9,
    code: 'IIIII',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 10,
    code: 'JJJJJ',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 11,
    code: 'KKKKK',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 12,
    code: 'LLLLL',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 13,
    code: 'MMMMM',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 14,
    code: 'NNNNN',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 15,
    code: 'OOOOO',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 16,
    code: 'PPPPP',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 17,
    code: 'QQQQQ',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 18,
    code: 'RRRRR',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 19,
    code: 'SSSSS',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 20,
    code: 'TTTTT',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 21,
    code: 'UUUUU',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 22,
    code: 'VVVVV',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 23,
    code: 'WWWWW',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 24,
    code: 'XXXXX',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 25,
    code: 'YYYYY',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },
  {
    id: 26,
    code: 'ZZZZZ',
    className: '1학년 3반',
    studentName: '김길동',
    connectedAt: '2023.01.01',
    state: '사용중',
  },

  // More people...
];
