import React from 'react';
import CodeHeader from './Header';
import CodeList from './List';
import CreateCodeModal from './Modal/createCode';
import UpdateCodeModal from './Modal/updateCode';

const AdminStudentCode = () => {
  return (
    <>
      <main className='flex-1'>
        <div className='flex flex-col '>
          <CodeHeader />
          <CodeList />
        </div>
      </main>
      <CreateCodeModal />
      <UpdateCodeModal />
    </>
  );
};

export default AdminStudentCode;
