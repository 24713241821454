import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LogoIcon from '../../assets/logo/Playible_Logo.svg';
import useInput from '../../utils/hooks/useInput';
import { useMutation } from '@tanstack/react-query';
import { logInTeacherAPI } from '../../apis/teacher';
import { queryClient } from '../..';
const Login = () => {
  const [account, onChangeAccount] = useInput<string>('');
  const [password, onChangePassword] = useInput<string>('');

  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: logInTeacherAPI,
    onSuccess: (data) => {
      sessionStorage.setItem('teacherAuth', data.token);
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['teacher'] });
      alert('로그인 성공');
      navigate('/');
    },
    onError: (err) => {
      console.error('error', err);
      alert('로그인 실패');
    },
  });
  const onSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      mutation.mutate({ account, password });
    },
    [account, navigate, password]
  );

  //   const onSubmit = useCallback(
  //     (e) => {
  //       e.preventDefault();

  //       if (account !== questionPaper[1].sol) {
  //         setAccountModal(true);
  //         return;
  //       }
  //       if (password !== questionPaper[2].sol) {
  //         setPasswordModal(true);
  //         return;
  //       }
  //       PlayerApi.changeProgress(progress, playerId)
  //         .then(() => {
  //           setSuccessModal(true);
  //         })
  //         .catch(() => {});
  //     },
  //     [account, password],
  //   );

  return (
    <>
      <section className="bg-gray-50 ">
        <div className="flex flex-col items-center justify-center h-screen px-6 py-8 mx-auto lg:py-0">
          <Link
            to="#"
            className="flex items-center mb-3 text-2xl font-semibold text-gray-900 sm:mb-6 "
          >
            <img className="w-32 mr-2 sm:w-40" src={LogoIcon} alt="logo" />
          </Link>
          <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 ">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              {/* <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl '>
                선생님 페이지
              </h1> */}
              <form className="space-y-4 md:space-y-6" onSubmit={onSubmit}>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-700 "
                  >
                    아이디
                  </label>
                  <input
                    type="text"
                    name="id"
                    id="id"
                    className="bg-gray-50 border border-gray-300 text-gray-700 sm:text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 "
                    placeholder="아이디를 입력해주세요"
                    required
                    value={account}
                    onChange={onChangeAccount}
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-700"
                  >
                    비밀번호
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-700 sm:text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 "
                    required
                    value={password}
                    onChange={onChangePassword}
                  />
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-start">
                    {/* <div className='flex items-center h-5'>
                      <input
                        id='remember'
                        aria-describedby='remember'
                        type='checkbox'
                        className='w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-red-300'
                        style={{ color: '#EF4444' }}
                      />
                    </div>
                    <div className='ml-3 text-sm'>
                      <label htmlFor='remember' className='text-gray-500 '>
                        Remember me
                      </label>
                    </div> */}
                  </div>
                  <Link
                    to="#"
                    className="text-sm font-medium text-red-500 hover:underline "
                  >
                    비밀번호가 기억나지 않습니까?
                  </Link>
                </div>
                <button
                  type="submit"
                  className="w-full text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  로그인
                </button>
                {/* <p className='text-sm font-light text-gray-500 '>
                  Don’t have an account yet?{' '}
                  <a
                    href='#'
                    className='font-medium text-red-500 hover:underline '
                  >
                    Sign up
                  </a>
                </p> */}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
