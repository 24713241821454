import React from 'react';
import AdminClassCode from '../../../containers/Admin/ClassCode';

export default function AdminClassCodePage() {
  return (
    <>
      <AdminClassCode />
    </>
  );
}
