import React from 'react';
import { useSetRecoilState } from 'recoil';
import { createClassModalState } from '../../../utils/recoil/modal';

const ClassHeader = () => {
  const setCreateClassModal = useSetRecoilState<boolean>(createClassModalState);
  const openModal = () => {
    setCreateClassModal(true);
  };

  return (
    <header className='xl:flex xl:items-center xl:justify-between'>
      <div className='flex-1 min-w-0'>
        <h1 className='text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>
          학급
        </h1>
      </div>
      <div className='flex mt-5 xl:mt-0 xl:ml-4'>
        {/* <span className='hidden sm:block'> */}
        <button
          type='button'
          className='inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none '
          onClick={openModal}
        >
          학급 생성하기
        </button>
        {/* </span> */}
      </div>
    </header>
  );
};

export default ClassHeader;
