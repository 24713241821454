import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { selectedProfileTabState } from '../../../utils/recoil/tab';
import { tabData } from './_data';
import { Tab } from './_interface';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export default function ProfileTab() {
  const selected = useRecoilValue<string>(selectedProfileTabState);

  const memoizedTabData = useMemo(() => tabData, []);
  const memoizedSelected = useMemo(() => selected, [selected]);

  return (
    <div className='mt-6 sm:mt-2 2xl:mt-5'>
      <div className='border-b border-gray-200'>
        <div className='max-w-5xl px-4 mx-auto sm:px-6 lg:px-8'>
          <nav className='flex -mb-px space-x-8' aria-label='Tabs'>
            {memoizedTabData.map((tab: Tab) => (
              <Link
                key={tab.name}
                to={tab.to}
                className={classNames(
                  tab.type === memoizedSelected
                    ? 'border-red-500 text-red-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                )}
                aria-current={
                  tab.type === memoizedSelected ? 'page' : undefined
                }
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
