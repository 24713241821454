import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import LogoIcon from '../../../assets/logo/Playible_Logo.svg';
import Admin from '../../../interfaces/admin';
import { loadAdminInfoAPI } from '../../../apis/admin';
import { useQuery } from '@tanstack/react-query';
const navigation = [
  { name: '학급', href: '/', icon: UsersIcon, current: false },
  { name: '코드', href: '/codes', icon: FolderIcon, current: false },
];
const options = [
  {
    id: 1,
    name: '프로필',
    href: '/admin/profile',
    initial: 'P',
    current: false,
  },
  { id: 2, name: '로그아웃', href: '#', initial: 'L', current: false },
];
const adminOptions = [
  {
    id: 1,
    name: '어드민 관리',
    href: '/admin/account',
    initial: 'A',
    current: false,
  },
  {
    id: 2,
    name: '선생님 관리',
    href: '/admin/teacher',
    initial: 'T',
    current: false,
  },
  {
    id: 3,
    name: '학급 관리',
    href: '/admin/classroom',
    initial: 'C',
    current: false,
  },
  {
    id: 4,
    name: '학생 관리',
    href: '/admin/student',
    initial: 'S',
    current: false,
  },
  {
    id: 5,
    name: '수업 관리',
    href: '/admin/classCode',
    initial: 'C',
    current: false,
  },
  {
    id: 6,
    name: '참가자 관리',
    href: '/admin/studentCode',
    initial: 'S',
    current: false,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function MainLayout2() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const logout = () => {
    const confirm = window.confirm('로그아웃하시겠습니까?');
    if (!confirm) return;
    if (sessionStorage.getItem('teacherAuth')) {
      sessionStorage.removeItem('teacherAuth');
      navigate('/login');
      window.location.reload();
    }
    if (sessionStorage.getItem('adminAuth')) {
      sessionStorage.removeItem('adminAuth');
      navigate('/admin');
      window.location.reload();
    }
  };
  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex flex-1 w-full max-w-xs mr-16">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 flex justify-center w-16 pt-5 left-full">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="w-6 h-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex flex-col px-6 pb-2 overflow-y-auto bg-white grow gap-y-5">
                    <div className="flex items-center h-16 shrink-0">
                      <img
                        src={LogoIcon}
                        className="w-auto h-10"
                        alt="Playible Company"
                      />
                    </div>
                    <nav className="flex flex-col flex-1">
                      <ul className="flex flex-col flex-1 gap-y-7">
                        <li className="flex-1">
                          <ul className="-mx-2 space-y-1">
                            {sessionStorage.getItem('teacherAuth') &&
                              navigation.map((item) => (
                                <li key={item.name}>
                                  <Link
                                    to={item.href}
                                    className={classNames(
                                      item.current
                                        ? 'bg-gray-50 text-red-600'
                                        : 'text-gray-700 hover:text-red-600 hover:bg-gray-50',
                                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                    )}
                                  >
                                    <item.icon
                                      className={classNames(
                                        item.current
                                          ? 'text-red-600'
                                          : 'text-gray-400 group-hover:text-red-600',
                                        'h-6 w-6 shrink-0'
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">
                            사용자 설정 메뉴
                          </div>
                          <ul className="mt-2 -mx-2 space-y-1">
                            {options.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    item.current
                                      ? 'bg-gray-50 text-red-600'
                                      : 'text-gray-700 hover:text-red-600 hover:bg-gray-50',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <span
                                    className={classNames(
                                      item.current
                                        ? 'text-red-600 border-red-600'
                                        : 'text-gray-400 border-gray-200 group-hover:border-red-600 group-hover:text-red-600',
                                      'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                    )}
                                  >
                                    {item.initial}
                                  </span>
                                  <span className="truncate">{item.name}</span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col px-6 overflow-y-auto bg-white border-r border-gray-200 grow gap-y-5">
            <div className="flex items-center h-16 shrink-0">
              <img
                src={LogoIcon}
                className="w-auto h-10"
                alt="Playible Company"
              />
            </div>
            <nav className="flex flex-col flex-1">
              <ul className="flex flex-col flex-1 gap-y-7">
                <li className="flex-1">
                  <ul className="-mx-2 space-y-1 ">
                    {sessionStorage.getItem('teacherAuth') &&
                      navigation.map((item) => (
                        <li key={item.name}>
                          <Link
                            to={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-gray-50 text-red-600'
                                : 'text-gray-700 hover:text-red-600 hover:bg-gray-50',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? 'text-red-600'
                                  : 'text-gray-400 group-hover:text-red-600',
                                'h-6 w-6 shrink-0'
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </li>
                {sessionStorage.getItem('adminAuth') && (
                  <li>
                    <div className="text-xs font-semibold leading-6 text-gray-400">
                      어드민 관리 메뉴
                    </div>
                    <ul className="mt-2 -mx-2 space-y-1">
                      {adminOptions.map((item) => (
                        <li key={item.name}>
                          <Link
                            to={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-gray-50 text-red-600'
                                : 'text-gray-700 hover:text-red-600 hover:bg-gray-50',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            )}
                          >
                            <span
                              className={classNames(
                                item.current
                                  ? 'text-red-600 border-red-600'
                                  : 'text-gray-400 border-gray-200 group-hover:border-red-600 group-hover:text-red-600',
                                'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                              )}
                            >
                              {item.initial}
                            </span>
                            <span className="truncate">{item.name}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                )}

                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">
                    사용자 설정 메뉴
                  </div>
                  <ul className="mt-2 -mx-2 space-y-1">
                    {options.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-50 text-red-600'
                              : 'text-gray-700 hover:text-red-600 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          {item.name === '로그아웃' ? (
                            <>
                              <span
                                className={classNames(
                                  item.current
                                    ? 'text-red-600 border-red-600'
                                    : 'text-gray-400 border-gray-200 group-hover:border-red-600 group-hover:text-red-600',
                                  'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                )}
                              >
                                {item.initial}
                              </span>

                              <span className="truncate" onClick={logout}>
                                {item.name}
                              </span>
                            </>
                          ) : (
                            sessionStorage.getItem('adminAuth') && (
                              <>
                                <span
                                  className={classNames(
                                    item.current
                                      ? 'text-red-600 border-red-600'
                                      : 'text-gray-400 border-gray-200 group-hover:border-red-600 group-hover:text-red-600',
                                    'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                  )}
                                >
                                  {item.initial}
                                </span>
                                <span className="truncate">{item.name}</span>
                              </>
                            )
                          )}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                {sessionStorage.getItem('teacherAuth') ? (
                  <li className="mt-auto -mx-6">
                    <Link
                      to="/profile"
                      className="flex items-center px-6 py-3 text-sm font-semibold leading-6 text-gray-900 gap-x-4 hover:bg-gray-50"
                    >
                      <img
                        className="w-8 h-8 rounded-full bg-gray-50"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                      <span className="sr-only">Your profile</span>
                      <span aria-hidden="true" className="text-gray-700">
                        선생님
                      </span>
                    </Link>
                  </li>
                ) : (
                  <li className="mt-auto -mx-6"></li>
                )}
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-10 flex items-center px-4 py-4 bg-white shadow-sm gap-x-6 sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden flex-1"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="w-6 h-6" aria-hidden="true" />
          </button>
          {/* <div className='flex-1 text-sm font-semibold leading-6 text-gray-900'>
            Dashboard
          </div> */}
          <Link to="/profile">
            <span className="sr-only">Your profile</span>
            <img
              className="w-8 h-8 rounded-full bg-gray-50"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </Link>
        </div>

        <main className="min-h-screen pt-8 bg-gray-50 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8 ">
            {/*  */}
            <Outlet />
            {/*  */}
          </div>
        </main>
      </div>
    </>
  );
}
