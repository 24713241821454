import axios from 'axios';
import { backUrl } from '../config/config';
import Admin from '../interfaces/admin';
import Teacher from '../interfaces/teacher';

axios.defaults.baseURL = backUrl;
axios.defaults.withCredentials = true;

axios.defaults.headers.common['token'] = sessionStorage.getItem('adminAuth');
export async function loadAdminInfoAPI() {
  axios.defaults.headers.common['token'] = sessionStorage.getItem('adminAuth');
  return await axios.get('admins/profile').then((res) => res.data);
}
export async function loadAllAdminsAPI() {
  return await axios.get('admins').then((res) => res.data);
}

export async function logInAdminAPI(data: Admin) {
  return await axios.post('admins/login', data).then((res) => res.data);
}

export async function registerAdminAPI(data: Admin) {
  return await axios.post(`admins`, data).then((res) => res.data);
}

export async function logoutAdminAPI() {
  return await axios.post(`admins/logout`).then((res) => res.data);
}

export async function updateAdminAPI(data: Admin) {
  return await axios.patch('admins', data).then((res) => res.data);
}

export async function updateMasterAdminAPI(data: Admin) {
  return await axios.patch('admins/master', data).then((res) => res.data);
}

export async function removeAdminAPI() {
  return await axios.delete('admins').then((res) => res.data);
}

export async function removeMasterAdminAPI(data: any) {
  return await axios.delete(`admins/master/${data.id}`).then((res) => res.data);
}

export async function loadAllTeacherByAdminAPI(data: any) {
  return await axios.get(`teachers/admin/master`, data).then((res) => res.data);
}

export async function loadAllTeacherBySlaveAdminAPI(data: any) {
  return await axios.get(`teachers/admin/slave`, data).then((res) => res.data);
}

export async function createTeacherByAdminAPI(data: Teacher) {
  return await axios
    .post(`teachers/admin/teachers`, data)
    .then((res) => res.data);
}

export async function editOneTeacherByAdminAPI(data: any) {
  return await axios
    .patch(`teachers/teachers/${data.id}`, data)
    .then((res) => res.data);
}

export async function removeTeacherByAdminAPI(data: any) {
  return await axios
    .delete(`teachers/teachers/${data.id}`)
    .then((res) => res.data);
}

export async function loadAllClassroomByMasterAdminAPI(data: any) {
  return await axios
    .get(`classrooms/admin/master`, data)
    .then((res) => res.data);
}

export async function loadAllClassroomBySlaveAdminAPI(data: any) {
  return await axios
    .get(`classrooms/admin/slave`, data)
    .then((res) => res.data);
}

export async function loadAllClassroomWithoutStudentBySlaveAdminAPI(data: any) {
  return await axios
    .get(`classrooms/admin/slave/no-student`, data)
    .then((res) => res.data);
}

export async function loadAllContentsByAdminMasterAPI(data: any) {
  return await axios
    .get(`admins/contents/master`, data)
    .then((res) => res.data);
}
export async function loadAllContentsByAdminSlaveAPI(data: any) {
  return await axios.get(`admins/contents/slave`, data).then((res) => res.data);
}
// export async function createClassroomByAdminAPI(data: Teacher) {
//   return await axios.post(`admins/teachers`, data).then((res) => res.data);
// }

// export async function editOneClassroomByAdminAPI(data: any) {
//   console.log('tefdfdf', data);
//   return await axios
//     .patch(`admins/teachers/${data.id}`, data)
//     .then((res) => res.data);
// }

// export async function removeClassroomByAdminAPI(data: any) {
//   return await axios
//     .delete(`admins/teachers/${data.id}`)
//     .then((res) => res.data);
// }
