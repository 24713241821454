import React from 'react';
import { StudentProps } from './_interface';

const StudentCard: React.FC<StudentProps> = ({ student }) => {
  const { name, status } = student;

  return (
    <div className="flex flex-row items-center gap-3 p-2 xs:gap-0 xs:p-5 xs:text-center xs:flex-1 xs:flex-col">
      {/* <img
        className='flex-shrink-0 mx-auto text-center rounded-full w-14 h-14 '
        src={'#'}
        alt=''
      /> */}
      <div className="items-center w-10 h-10 bg-gray-200 rounded-full xs:mx-auto xs:flex-shrink-0 xs:w-14 xs:h-14">
        {/* {student.seq} */}
      </div>
      <h3 className="text-gray-900 xs:text-xl xs:mt-4 xs:font-bold">{name}</h3>
      <dl className="xs:flex xs:flex-col xs:text-center xs:align-middle xs:flex-grow xs:justify-between">
        {/* <dt className="sr-only">Student Code</dt>
        <dd className="mt-2 text-base text-gray-500">
          {student.code}
        </dd> */}
        <dt className="sr-only">Student Status</dt>
        <dd>
          {student.status ? (
            <span className="px-2 py-1 text-xs font-medium text-green-800 bg-green-100 rounded-full">
              접속중
            </span>
          ) : (
            <span className="px-2 py-1 text-xs font-medium text-black-800 bg-gray-100 rounded-full">
              미접속
            </span>
          )}
        </dd>
      </dl>
    </div>
  );
};
export default StudentCard;
