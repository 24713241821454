import React from 'react';
import AdminLogin from '../../containers/Admin/Login';

const AdminPage = () => {
  return (
    <>
      <AdminLogin />
    </>
  );
};

export default AdminPage;
