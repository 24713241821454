import React from 'react';
import Codes from '../../containers/Codes';

export default function CodesPage() {
  return (
    <>
      <Codes />
    </>
  );
}
