import React from 'react';
import AdminAccount from '../../../containers/Admin/Account';
import axios from 'axios';

export default function AdminAccountPage() {
  const token = sessionStorage.getItem('adminAuth');
  axios.defaults.headers.common['token'] = token;
  return (
    <>
      <AdminAccount />
    </>
  );
}
