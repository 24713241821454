import React, { useCallback } from 'react';
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { LectureProps } from './_interface';
import { useSetRecoilState } from 'recoil';
import {
  joinLectureLinkModalState,
  joinLectureModalState,
} from '../../../utils/recoil/modal';
import {
  joinLectureCodeState,
  joinLectureLinkState,
} from '../../../utils/recoil/data';

const LectureCard: React.FC<LectureProps> = ({ lecture }) => {
  const setJoinLectureModal = useSetRecoilState<boolean>(joinLectureModalState);
  const setJoinLectureCode = useSetRecoilState<string>(joinLectureCodeState);
  const setJoinLectureLinkModal = useSetRecoilState<boolean>(
    joinLectureLinkModalState
  );
  const setJoinLectureLink = useSetRecoilState<string>(joinLectureLinkState);
  const navigate = useNavigate();
  const moveLecturePage = useCallback(() => {
    navigate(`/lectures/${lecture.id}`, {
      state: { name: lecture.content.name },
    });
  }, [lecture.id, lecture.content.name, navigate]);
  const handle = {
    joinCode: () => {
      setJoinLectureCode(lecture.code);
      setJoinLectureModal(true);
    },
    joinLink: () => {
      setJoinLectureLink(lecture.content.url);
      setJoinLectureLinkModal(true);
    },
  };
  return (
    <div className='flex flex-col flex-1 gap-4 sm:flex-row'>
      {/* 수업 모달 */}
      <div className='flex flex-col p-4 bg-white shadow xl:p-5 rounded-2xl'>
        <div className='flex items-center '>
          {lecture.status === 'Before' ? (
            <span className='px-2 py-1 mr-2 text-xs font-medium text-gray-800 bg-gray-100 rounded-full'>
              수업 예정
            </span>
          ) : lecture.status === 'Ing' ? (
            <span className='px-2 py-1 mr-2 text-xs font-medium text-green-800 bg-green-100 rounded-full'>
              수업 진행
            </span>
          ) : (
            <span className='px-2 py-1 mr-2 text-xs font-medium text-red-800 bg-red-100 rounded-full'>
              수업 종료
            </span>
          )}
          {/* <button onClick={() => {}}>
                <ChevronRightIcon className='w-3 h-3 text-gray-400' />
              </button> */}
          <span className='px-2 py-1 text-xs font-medium text-red-800 bg-red-100 rounded-full'>
            참여코드: {lecture.code}
          </span>
        </div>
        <div className='flex flex-col flex-1 gap-2 pt-2 xl:pt-3 xl:gap-4 xl:w-[640px]'>
          <h2 className='text-lg font-bold text-black'>{lecture.name}</h2>
          <p className='flex-1 text-xs text-gray-400 line-clamp-3 text-start'>
            {lecture.info}
          </p>
        </div>
        <div className='flex items-center justify-between'>
          <div className='flex flex-row gap-2'>
            <button
              type='button'
              className='inline-flex items-center px-4 py-1 text-xs text-gray-400 border border-gray-300 rounded-lg'
              onClick={handle.joinLink}
            >
              <ArrowUpOnSquareIcon className='w-5 h-5' aria-hidden='true' />
              초대링크
            </button>
            <button
              type='button'
              className='inline-flex items-center px-4 py-1 text-xs text-gray-400 border border-gray-300 rounded-lg'
              onClick={handle.joinCode}
            >
              <ArrowUpOnSquareIcon className='w-5 h-5' aria-hidden='true' />
              참여코드
            </button>
          </div>
          <button
            className='px-8 py-2 text-xs text-white bg-gray-700 rounded-lg xs:px-10'
            onClick={moveLecturePage}
          >
            수업 상세
          </button>
        </div>
      </div>
      {/* 콘텐츠 모달 */}
      <div className='hidden p-4 text-center bg-white divide-y divide-gray-200 shadow sm:block xl:p-5 rounded-2xl xs:text-left'>
        <div className='flex flex-col items-center gap-6 xs:items-stretch sm:gap-2 xs:flex-row sm:flex-col xl:gap-4'>
          <div className='h-40 rounded-lg w-60 sm:w-36 sm:h-24 xl:w-40 xl:h-28 bg-gray-50'></div>
          <div className='flex flex-col gap-1 xl:gap-2'>
            <h2 className='pt-0 text-lg font-semibold xs:pt-4 sm:pt-0 sm:text-sm xl:font-bold xl:text-base'>
              {lecture.content.name}
            </h2>
            <p className='text-xs text-gray-400'>
              크리에이터 이름&ensp;{lecture.content.info}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LectureCard;
