import React from 'react';
import LiveFeed from '../../containers/LiveFeed';

export default function LiveFeedPage() {
  return (
    <>
      <LiveFeed />
    </>
  );
}
