import Notice from '../../components/Common/Alert/notice';
import Button from '../../components/Common/Button';
import NumberInput from '../../components/Common/Inputs/Number';
import Toggle from '../../components/Common/Toggle';

export default function SetUp() {
  return (
    <div className='flex flex-col'>
      <div className='self-center w-full max-w-[1000px] py-10'>
        <h1 className='text-3xl font-bold text-gray-900'>수업 준비</h1>
        <form className='mt-6 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2'>
          <div className='px-4 py-6 sm:p-8'>
            <div className='max-w-2xl space-y-10'>
              <fieldset>
                <legend className='text-sm font-semibold leading-6 text-gray-900'>
                  게임 설정
                </legend>
                <div className='mt-6 space-y-4'>
                  <Toggle text={'인트로 스토리 영상'} />
                  <Notice
                    name={'Notice'}
                    text={
                      '토글 버튼을 활성화하면 초기 인트로 스토리 영상이 재생됩니다.'
                    }
                  />
                  <NumberInput
                    name={'게임 종료조건 (몬스터 라운드)'}
                    placeholder={'7'}
                  />
                  <Button
                    label={'코드 초기화'}
                    text={'학생코드로 이동'}
                    href={'/codes'}
                  />
                </div>
              </fieldset>
            </div>
          </div>
          <div className='flex items-center justify-end px-4 py-4 border-t gap-x-6 border-gray-900/10 sm:px-8'>
            <button
              type='button'
              className='text-sm font-semibold leading-6 text-gray-900'
            >
              취소
            </button>
            <button
              type='submit'
              className='px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >
              저장
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
