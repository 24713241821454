import React from 'react';
import Profile from '../../containers/Profile';

export default function ProfilePage() {
  return (
    <>
      <Profile />
    </>
  );
}
