import React from 'react';
import Status from '../../containers/Status';

export default function StatusPage() {
  return (
    <>
      <Status />
    </>
  );
}
