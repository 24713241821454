import Feed from './feed';

const activity = [
  {
    id: 1,
    type: 'image',
    person: { name: '신짱구', to: '#' },
    date: '00월 00일 오후 00:00:00',
    mission: {
      name: '미션이름1',
      question: '이미지 미션 문제 내용이 들어갈 공간입니다.',
      answer: 'https://via.placeholder.com/400x300',
    },
  },
  {
    id: 2,
    type: 'text',
    person: { name: '한유리', to: '#' },
    date: '00월 00일 오후 00:00:00',
    mission: {
      name: '미션이름2',
      question: '텍스트 미션 문제 내용이 들어갈 공간입니다.',
      answer:
        '텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.텍스트 미션 정답 내용이 들어갈 공간입니다.',
    },
  },
  {
    id: 3,
    type: 'gps',
    person: { name: '김철수', to: '#' },
    date: '00월 00일 오후 00:00:00',
    mission: {
      name: '미션이름3',
      question: 'GPS 미션 문제 내용이 들어갈 공간입니다.',
      answer: 'https://via.placeholder.com/400x300',
    },
  },
  {
    id: 4,
    type: 'image',
    person: { name: '이훈이', to: '#' },
    date: '00월 00일 오후 00:00:00',
    mission: {
      name: '미션이름4',
      question: '이미지 미션 문제 내용이 들어갈 공간입니다.',
      answer: 'https://via.placeholder.com/400x300',
    },
  },
];

export default function LiveFeed() {
  return (
    <div className='flex flex-col'>
      <div className='self-center w-full max-w-[1000px] py-10'>
        <h1 className='text-3xl font-bold text-gray-900'>라이브 피드</h1>
        <form className='mt-6 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2'>
          <div className='px-4 py-6 sm:p-8'>
            <div className='flow-root'>
              <ul role='feed' className='-mb-8'>
                {activity.map((feedItem, feedItemIdex) => (
                  <li key={feedItem.id}>
                    <div className='relative pb-8'>
                      {feedItemIdex !== activity.length - 1 ? (
                        <span
                          className='absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200'
                          aria-hidden='true'
                        />
                      ) : null}
                      <div className='relative flex items-start space-x-3'>
                        <Feed feedItem={feedItem} />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
