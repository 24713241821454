import { atom } from 'recoil';
import Classroom from '../../../interfaces/classroom';
import Teacher from '../../../interfaces/teacher';
import { Lecture } from '../../../containers/ClassDetail/LectureList/_interface';

export * from './data';

export const classroomDataState = atom<Classroom | null>({
  key: 'classroomDataState',
  default: {},
});

export const teacherDataState = atom<Teacher | null>({
  key: 'teacherDataState',
  default: {},
});

export const lectureDataState = atom<Lecture | null>({
  key: 'lectureDataState',
  default: {},
});

export const introVideoState = atom<boolean>({
  key: 'introVideoState',
  default: false,
});
