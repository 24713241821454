import React from 'react';
import AdminLecture from '../../../containers/Admin/Lecture';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { loadLectureListByAdminAPI } from '../../../apis/lecture';

export default function AdminLecturePage() {
  const location = useLocation();
  const classroomId = location.pathname.split('/')[3];
  const { data: lectures } = useQuery(['lectures', classroomId], () =>
    loadLectureListByAdminAPI(+classroomId)
  );

  return (
    <>
      <AdminLecture classroomId={+classroomId} lectures={lectures} />
    </>
  );
}
