import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';

export default function Info({ name, text }: any) {
  return (
    <div className='p-4 bg-white rounded-md'>
      <div className='flex items-center'>
        <div className='flex-shrink-0'>
          <InformationCircleIcon
            className='w-5 h-5 text-gray-400'
            aria-hidden='true'
          />
        </div>
        <div className='self-center ml-3'>
          {name && (
            <h3 className='text-sm font-medium text-gray-800'>{name}</h3>
          )}
          {text && <p className='mt-2 text-sm text-gray-700'>{text}</p>}
        </div>
      </div>
    </div>
  );
}
