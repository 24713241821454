import React, { useEffect } from 'react';
import Classes from '../../containers/Classes';
import { useNavigate } from 'react-router-dom';

export default function ClassesPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !sessionStorage.getItem('teacherAuth') &&
      !sessionStorage.getItem('adminAuth')
    ) {
      navigate('/login');
      window.location.reload();
    } else if (sessionStorage.getItem('adminAuth')) {
      navigate('/admin/account');
      window.location.reload();
    }
  }, []);

  return (
    <>
      <Classes />
    </>
  );
}
