import { useEffect, Dispatch, SetStateAction } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { updateClassroomAdminModalState } from '../../../../utils/recoil/modal';
import { useQuery } from '@tanstack/react-query';
import { removeClassroomAPI } from '../../../../apis/classroom';
import Classroom from '../../../../interfaces/classroom';
import { updateClassroomState } from '../../../../utils/recoil/data';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../../../..';
import Admin from '../../../../interfaces/admin';
import { loadAllClassroomWithoutStudentBySlaveAdminAPI } from '../../../../apis/admin';

interface Props {
  admin: Admin;
  setClassroomCount: Dispatch<SetStateAction<number>>;
}
export default function SlaveTable({ admin, setClassroomCount }: Props) {
  const { data: classrooms } = useQuery(
    ['classrooms-teachers'],
    loadAllClassroomWithoutStudentBySlaveAdminAPI
  );
  const navigate = useNavigate();
  useEffect(() => {
    classrooms && setClassroomCount(classrooms.length);
  }, [classrooms]);
  const setUpdateClassroomModal = useSetRecoilState<boolean>(
    updateClassroomAdminModalState
  );

  const setUpdateClassroomState =
    useSetRecoilState<Classroom>(updateClassroomState);

  const deleteMutation = useMutation({
    mutationFn: removeClassroomAPI,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['deleteClassroom'] });
      alert('삭제 성공');
    },
    onError: (err) => {
      console.error('error', err);
      alert('삭제 실패');
    },
  });

  const handle = {
    move: (e: any) => {
      navigate(`/admin/classroom/${e}`);
    },
    update: (e: any) => {
      setUpdateClassroomState({
        id: classrooms[e].id,
        name: classrooms[e].name,
        teacher: classrooms[e].teacher,
      });
      setUpdateClassroomModal(true);
    },
    delete: (id: any) => {
      const confirm = window.confirm('삭제하시겠습니까?');
      if (!confirm) return;
      deleteMutation.mutate({ id });
    },
  };
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
          >
            <Link to="#" className="inline-flex group">
              No
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              학급 이름
              <span className="flex-none ml-2 text-gray-900 bg-gray-100 rounded group-hover:bg-gray-200">
                <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              학급 코드
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              담당 선생님
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              담당 관리자
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              생성 시간
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <span className="inline-flex group">
              수정
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </span>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <span className="inline-flex group">
              삭제
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {classrooms &&
          classrooms.map((classroom: Classroom, i: number) => (
            <tr key={classroom.id}>
              <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">
                {i + 1}
              </td>
              <td
                className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap cursor-pointer"
                onClick={() => handle.move(classroom.id)}
              >
                {classroom.name}
              </td>
              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                {classroom.code}
              </td>
              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                {classroom.teacher.name}
              </td>
              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                {admin && admin.name}
              </td>
              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                {classroom.createdAt.toString().split('T')[0]}
              </td>
              <td className="relative py-4 pl-3 pr-4 text-sm text-left whitespace-nowrap sm:pr-0">
                <button
                  className="text-red-600 hover:text-red-900"
                  onClick={() => handle.update(i)}
                >
                  수정<span className="sr-only">, {classroom.id}</span>
                </button>
              </td>
              <td className="relative py-4 pl-3 pr-4 text-sm text-left whitespace-nowrap sm:pr-0">
                <button
                  className="text-red-600 hover:text-red-900"
                  onClick={() => handle.delete(classroom.id)}
                >
                  삭제<span className="sr-only">, {classroom.id}</span>
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
