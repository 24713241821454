import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedProfileTabState } from '../../utils/recoil/tab';
import ProfileHeader from './Header';
import ProfileList from './List';
import ProfileTab from './Tab';
import { useQuery } from '@tanstack/react-query';
import Teacher from '../../interfaces/teacher';
import { loadTeacherInfoAPI } from '../../apis/teacher';

export default function Profile() {
  const selected = useRecoilValue<string>(selectedProfileTabState);
  const { data: teacher } = useQuery<Teacher>(['teacher'], loadTeacherInfoAPI);

  const renderList = useMemo(() => {
    switch (selected) {
      case 'info':
        return <ProfileList teacher={teacher} />;
      default:
        return <></>;
    }
  }, [selected]);

  return (
    <>
      <div className="flex h-full">
        <div className="flex flex-col flex-1 min-w-0 overflow-hidden">
          <div className="relative z-0 flex flex-1 overflow-hidden">
            <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
              <article>
                <ProfileHeader teacher={teacher} />
                <ProfileTab />
                {renderList}
              </article>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
