import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { updateAdminLectureModalState } from '../../../../utils/recoil/modal';
import { useMutation } from '@tanstack/react-query';
import Admin from '../../../../interfaces/admin';
import { updateLectureState } from '../../../../utils/recoil/data';
import { queryClient } from '../../../..';
import { Lecture } from '../../../ClassDetail/LectureList/_interface';
import { Dispatch, SetStateAction } from 'react';
import { removeLectureAdminAPI } from '../../../../apis/lecture';
import { useQuery } from '@tanstack/react-query';
import Classroom from '../../../../interfaces/classroom';
import { loadClassroomInfoAdminAPI } from '../../../../apis/classroom';

interface Props {
  admin: Admin;
  lectures: Lecture[];
  classroomId: number;
}

export default function Table({ admin, lectures, classroomId }: Props) {
  let count = 0;
  const { data: classroom } = useQuery<Classroom>(['classroom'], () =>
    loadClassroomInfoAdminAPI({ id: classroomId })
  );
  const setUpdateAdminLectureModal = useSetRecoilState<boolean>(
    updateAdminLectureModalState
  );
  const setUpdateLecture = useSetRecoilState<Lecture>(updateLectureState);
  const deleteMutation = useMutation({
    mutationFn: removeLectureAdminAPI,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['deleteLecture'] });
      alert('삭제 성공');
    },
    onError: (err) => {
      console.error('error', err);
      alert('삭제 실패');
    },
  });
  const handle = {
    update: (lecture: any) => {
      setUpdateLecture({
        id: lecture.id,
        name: lecture.name,
        info: lecture.info,
        status: lecture.status,
      });
      setUpdateAdminLectureModal(true);
    },
    delete: (id: any) => {
      const confirm = window.confirm('삭제하시겠습니까?');
      if (!confirm) return;
      deleteMutation.mutate({ id });
    },
  };
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
          >
            <Link to="#" className="inline-flex group">
              No
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              이름
              <span className="flex-none ml-2 text-gray-900 bg-gray-100 rounded group-hover:bg-gray-200">
                <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              코드
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              학급
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              컨텐츠
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              담당 관리자
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              생성 시간
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              사용 여부
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <span className="inline-flex group">
              수정
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </span>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <span className="inline-flex group">
              삭제
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {lectures &&
          lectures.map((lecture: any, j: number) => {
            count++;
            return (
              <tr key={lecture.name + j}>
                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">
                  {count}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {lecture.name}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {lecture.code}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {classroom.name}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {lecture.content.name}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {admin && admin.name}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {lecture.createdAt.toString().split('T')[0]}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {lecture.status === 'Before'
                    ? '진행전'
                    : lecture.status === 'Ing'
                    ? '진행중'
                    : '완료'}
                </td>
                <td className="relative py-4 pl-3 pr-4 text-sm text-left whitespace-nowrap sm:pr-0">
                  <button
                    className="text-red-600 hover:text-red-900"
                    onClick={() => handle.update(lecture)}
                  >
                    수정<span className="sr-only">, {lecture.id}</span>
                  </button>
                </td>
                <td className="relative py-4 pl-3 pr-4 text-sm text-left whitespace-nowrap sm:pr-0">
                  <button
                    className="text-red-600 hover:text-red-900"
                    onClick={() => handle.delete(lecture.id)}
                  >
                    삭제<span className="sr-only">, {lecture.id}</span>
                  </button>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}
