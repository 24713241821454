import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { updateContentModalState } from '../../../utils/recoil/modal';
import Toggle from '../../../components/Common/Toggle';
import Notice from '../../../components/Common/Alert/notice';
import NumberInput from '../../../components/Common/Inputs/Number';
import Button from '../../../components/Common/Button';
import { useMutation } from '@tanstack/react-query';
import { updateContentLectureAPI } from '../../../apis/lecture';
import { introVideoState, lectureDataState } from '../../../utils/recoil/data';
import { Lecture } from '../../ClassDetail/LectureList/_interface';

const UpdateContentModal = () => {
  const [updateContentModal, setUpdateContentModal] = useRecoilState<boolean>(
    updateContentModalState
  );
  const [isIntroVideoEnabled, setIsIntroVideoEnabled] =
    useRecoilState(introVideoState);
  const { id } = useRecoilValue<Lecture>(lectureDataState);

  const [name, setName] = useState(''); // 학급 이름을 저장하는 state
  const [info, setInfo] = useState(''); // 학급 이름을 저장하는 state

  const closeModal = () => {
    setName(''); // 학급 이름 state 초기화
    setInfo('');
    setUpdateContentModal(false);
  };

  const mutation = useMutation({
    mutationFn: updateContentLectureAPI,
    onSuccess: (data) => {
      setIsIntroVideoEnabled(data.isIntroVideoEnabled);
    },
  });

  const onSubmit = () => {
    mutation.mutate({ isIntroVideoEnabled: isIntroVideoEnabled, id: id });
    window.confirm('콘텐츠 설정 완료');
    closeModal();
  };

  return (
    <Transition.Root show={updateContentModal} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 transition-opacity bg-opacity-75 bg-gray-900/80' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                <div>
                  <div className='mt-5 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h1'
                      className='pb-4 text-2xl font-bold leading-6 text-gray-900'
                    >
                      콘텐츠 설정
                    </Dialog.Title>
                  </div>
                </div>
                <div className='mt-6'>
                  <div className='max-w-2xl space-y-10'>
                    <div>
                      <span className='text-sm font-semibold leading-6 text-gray-900'>
                        게임 설정
                      </span>
                      <div className='mt-6 space-y-4'>
                        <Toggle text={'인트로 스토리 영상'} />
                        <Notice
                          name={'Notice'}
                          text={
                            '토글 버튼을 활성화하면 초기 인트로 스토리 영상이 재생됩니다.'
                          }
                        />
                        <NumberInput
                          name={'게임 종료조건 (몬스터 라운드)'}
                          placeholder={'7'}
                          /* 이거 바꾸면 몬스터 위치 변경 */
                        />
                        <Button
                          label={'코드 초기화'}
                          text={'학생코드로 이동'}
                          href={'/codes'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='mt-10 mb-5 sm:mt-10 sm:mb-5'>
                    <button
                      type='submit'
                      className='inline-flex items-center justify-center w-full px-4 py-2 text-base font-bold text-white bg-red-500 border border-transparent rounded-md shadow-sm hover:bg-gray-200 focus:outline-none h-11 sm:text-sm'
                      onClick={onSubmit}
                    >
                      저장
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UpdateContentModal;
