import React from 'react';
import DashBoardHeader from './Header';
import DashBoardList from './List';
import UpdateContentModal from './Modal/updateContent';
import UpdateLectureModal from './Modal/updateLecture';
import JoinLectureModal from './Modal/joinLecture';
import { loadTeacherInfoAPI } from '../../apis/teacher';
import { useQuery } from '@tanstack/react-query';
import Teacher from '../../interfaces/teacher';
import { useLocation } from 'react-router-dom';
import { Lecture } from '../ClassDetail/LectureList/_interface';
import { loadLectureInfoAPI } from '../../apis/lecture';
import { useSetRecoilState } from 'recoil';
import { lectureDataState } from '../../utils/recoil/data';

const DashBoard = () => {
  const { data: teacher } = useQuery<Teacher>(['teacher'], loadTeacherInfoAPI);
  const location = useLocation();
  const path = parseInt(location.pathname.split('/')[2]);
  const setLectureData = useSetRecoilState<Lecture>(lectureDataState);
  const { data: lecture } = useQuery<Lecture>(
    ['lecture', path],
    () => loadLectureInfoAPI({ id: path }),
    {
      onSuccess: (data) => {
        setLectureData(data);
      },
    }
  );
  return (
    <>
      <main className='flex-1'>
        <div className='flex flex-col '>
          <DashBoardHeader lecture={lecture} />
          <DashBoardList lecture={lecture} />
        </div>
      </main>
      <UpdateLectureModal />
      <UpdateContentModal />
      <JoinLectureModal />
    </>
  );
};

export default DashBoard;
