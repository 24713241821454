import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { useRecoilState } from 'recoil';
import { createAdminModalState } from '../../../../utils/recoil/modal';
import { useMutation } from '@tanstack/react-query';
import { registerAdminAPI } from '../../../../apis/admin';
import { queryClient } from '../../../..';
import { Role } from '../../../../interfaces/admin';

const CreateAdminModal = () => {
  const [createAdminModal, setCreateAdminModal] = useRecoilState<boolean>(
    createAdminModalState
  );

  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState<Role>();
  const [active, setActive] = useState<boolean>(false);
  const [activeVal, setActiveVal] = useState<string>('');
  const mutation = useMutation({
    mutationFn: registerAdminAPI,
    onSuccess: (data) => {
      console.log('관리자', data);
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['new-admin'] });
      alert('관리자 등록 성공');
    },
    onError: (err) => {
      console.error('error', err);
      alert('관리자 등록 실패');
    },
  });
  const closeModal = () => {
    setId(''); // 학급 이름 state 초기화
    setPassword('');
    setActive(false);
    setActiveVal('');
    setCreateAdminModal(false);
  };
  const handleSelect = (e: any) => {
    setRole(e.target.value);
  };
  const handleSelectActive = (e: any) => {
    const val = e.target.value;
    if (val === 'Yes') {
      setActive(true);
      setActiveVal('Yes');
    } else if (val === 'No') {
      setActive(false);
      setActiveVal('No');
    }
  };
  const onSubmit = () => {
    mutation.mutate({ name, account: id, password, role, active });
    closeModal();
  };

  return (
    <Transition.Root show={createAdminModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-opacity-75 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  {/* <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                  <CheckIcon
                    className="w-6 h-6 text-green-600"
                    aria-hidden="true"
                  />
                </div> */}
                  {/* <div className="flex flex-col flex-1 p-8"></div> */}
                  <div className="mt-5 text-center sm:mt-5">
                    <Dialog.Title
                      as="h1"
                      className="pb-4 text-2xl font-bold leading-6 text-gray-900"
                    >
                      어드민 생성
                    </Dialog.Title>
                    {/* <Dialog.Description
                      as='p'
                      className='pt-4 leading-6 text-gray-700 text-'
                    >
                      1 / 3
                    </Dialog.Description> */}
                    <div className="mt-2">
                      <div className="relative flex mt-2 rounded-md shadow-sm sm:mt-4">
                        <input
                          type="text"
                          id="admin-name"
                          name="admin-name"
                          placeholder="이름을 입력해주세요"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          autoComplete="false"
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-5 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                        />
                      </div>
                      <div className="relative flex mt-2 rounded-md shadow-sm sm:mt-4">
                        <input
                          type="text"
                          id="admin-id"
                          name="admin-id"
                          placeholder="아이디를 입력해주세요"
                          value={id}
                          onChange={(e) => setId(e.target.value)}
                          autoComplete="false"
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-5 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                        />
                      </div>
                      <div className="relative flex mt-2 rounded-md shadow-sm sm:mt-4">
                        <input
                          type="password"
                          id="admin-password"
                          name="admin-password"
                          placeholder="비밀번호를 입력해주세요"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          autoComplete="new-password"
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-10 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                        />
                      </div>
                      <div className="relative mt-2 rounded-md shadow-sm sm:mt-4">
                        <label htmlFor="selected-tab" className="sr-only">
                          Select a tab
                        </label>
                        <select
                          id="selected-tab"
                          name="selected-tab"
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-10 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                          value={role}
                          onChange={handleSelect}
                        >
                          <option value="" disabled>
                            관리자 등급을 선택해주세요
                          </option>
                          <option value={'Master'}>Master</option>
                          <option value={'Slave'}>Slave</option>
                        </select>
                      </div>
                      <div className="relative mt-2 rounded-md shadow-sm sm:mt-4">
                        <label htmlFor="selected-tab" className="sr-only">
                          Select a tab
                        </label>
                        <select
                          id="selected-tab"
                          name="selected-tab"
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-10 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                          value={activeVal}
                          onChange={handleSelectActive}
                        >
                          <option value="" disabled>
                            활성화 여부를 선택해주세요
                          </option>
                          <option value={'Yes'}>Yes</option>
                          <option value={'No'}>No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10 mb-5 sm:mt-10 sm:mb-5">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-bold text-white bg-red-500 border border-transparent rounded-md shadow-sm hover:bg-gray-200 focus:outline-none h-11 sm:text-sm"
                    onClick={onSubmit}
                  >
                    생성하기
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateAdminModal;
