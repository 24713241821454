import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  updateLectureModalState,
  updateContentModalState,
  readLectureCodeModalState,
  lectureStatusState,
} from '../../../utils/recoil/modal';
import { Lecture } from '../../ClassDetail/LectureList/_interface';
import { joinLectureCodeState } from '../../../utils/recoil/data';
import { useMutation, useQuery } from '@tanstack/react-query';
import { socket } from '../../../config/config';
import {
  findContentLectureAPI,
  updateContentLectureAPI,
} from '../../../apis/lecture';
import { useEffect } from 'react';

interface Props {
  lecture: Lecture;
}

export default function DashBoardHeader({ lecture }: Props) {
  const mutation = useMutation({
    mutationFn: updateContentLectureAPI,
    onSuccess: (data) => {
      console.log('updated', data);
    },
  });

  const { data: isFinished } = useQuery({
    queryKey: ['isFinished', lecture?.id],
    queryFn: () => (lecture ? findContentLectureAPI(lecture.id) : undefined),
    enabled: !!lecture,
  });

  const setUpdateLectureModal = useSetRecoilState<boolean>(
    updateLectureModalState
  );
  const setUpdateContentModal = useSetRecoilState<boolean>(
    updateContentModalState
  );
  const setReadLectureCodeModal = useSetRecoilState<boolean>(
    readLectureCodeModalState
  );

  const setJoinLectureCode = useSetRecoilState<string>(joinLectureCodeState);
  const [lectureStatus, setLectureStatus] = useRecoilState(lectureStatusState);

  const handle = {
    code: () => {
      setReadLectureCodeModal(true);
      lecture && setJoinLectureCode(lecture.code);
    },
    gameOff: () => {
      mutation.mutate({ isFinished: true, id: lecture.id });
      socket.emit('gameStatus', { lectureId: lecture.id, status: 'off' });
      setLectureStatus(true);
    },
    gameOn: () => {
      mutation.mutate({ isFinished: false, id: lecture.id });
      socket.emit('gameStatus', { lectureId: lecture.id, status: 'on' });
      setLectureStatus(false);
    },
  };

  useEffect(() => {
    if (isFinished) setLectureStatus(isFinished);
  }, [isFinished]);

  return (
    <header className='xl:flex xl:items-center xl:justify-between'>
      <div className='flex-1 min-w-0'>
        <h1 className='my-6 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>
          수업 상세
        </h1>
      </div>
      <div className='flex gap-2 mt-5 xl:mt-0 xl:ml-4'>
        {/* <span className='hidden sm:block'> */}
        <button
          type='button'
          className='inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none '
          onClick={() => setUpdateLectureModal(true)}
        >
          수업정보 설정하기
        </button>
        <button
          type='button'
          className='inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none '
          onClick={() => setUpdateContentModal(true)}
        >
          콘텐츠 설정하기
        </button>
        <button
          type='button'
          className='inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none '
          // onClick={() => setCreateAdminModal(true)}
        >
          수업결과 다운로드
        </button>
        <button
          type='button'
          className='inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none '
          onClick={handle.code}
        >
          수업 코드
        </button>
        {lectureStatus ? (
          <button
            type='button'
            className='inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none '
            onClick={handle.gameOn}
          >
            게임 시작
          </button>
        ) : (
          <button
            type='button'
            className='inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none '
            onClick={handle.gameOff}
          >
            게임 종료
          </button>
        )}
        {/* </span> */}
      </div>
    </header>
  );
}
