import React from 'react';
import ClassDetail from '../../containers/ClassDetail';
import { useNavigate } from 'react-router-dom';

export default function ClassDetailPage() {
  const navigate = useNavigate();
  if (
    !sessionStorage.getItem('teacherAuth') &&
    !sessionStorage.getItem('adminAuth')
  ) {
    navigate('/login');
    window.location.reload();
  }
  return (
    <>
      <ClassDetail />
    </>
  );
}
