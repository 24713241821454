import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { joinClassModalState } from '../../../utils/recoil/modal';
import Classroom from '../../../interfaces/classroom';
import { classroomDataState } from '../../../utils/recoil/data';

const JoinClassModal = () => {
  const [joinClassModal, setJoinClassModal] =
    useRecoilState<boolean>(joinClassModalState);
  const { code } = useRecoilValue<Classroom>(classroomDataState);
  const closeModal = () => {
    setJoinClassModal(false);
  };

  const onSubmit = () => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        alert('학급코드가 복사되었습니다');
      })
      .catch((error) => {
        alert(`학급코드 복사 오류: ${error}`);
      });
    closeModal();
  };

  return (
    <Transition.Root show={joinClassModal} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 transition-opacity bg-opacity-75 bg-gray-900/80' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                <div>
                  {/* <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                  <CheckIcon
                    className="w-6 h-6 text-green-600"
                    aria-hidden="true"
                  />
                </div> */}
                  {/* <div className="flex flex-col flex-1 p-8"></div> */}
                  <div className='mt-5 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-2xl font-bold leading-6 text-gray-900'
                    >
                      학급 참여하기
                    </Dialog.Title>
                    <div className='mt-8'>
                      <label className='text-lg font-bold text-gray-700 '>
                        학급코드
                      </label>
                      <div className='flex gap-3 mt-10'>
                        {/* map 사용 */}
                        <input
                          type='text'
                          name='text'
                          id='code'
                          value={code?.charAt(0)}
                          disabled
                          className='block w-full h-20 font-bold text-center border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-xl'
                        />
                        <input
                          type='text'
                          name='text'
                          id='code'
                          value={code?.charAt(1)}
                          disabled
                          className='block w-full h-20 font-bold text-center border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-xl'
                        />
                        <input
                          type='text'
                          name='text'
                          id='code'
                          value={code?.charAt(2)}
                          disabled
                          className='block w-full h-20 font-bold text-center border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-xl'
                        />
                        <input
                          type='text'
                          name='text'
                          id='code'
                          value={code?.charAt(3)}
                          disabled
                          className='block w-full h-20 font-bold text-center border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-xl'
                        />
                        <input
                          type='text'
                          name='text'
                          id='code'
                          value={code?.charAt(4)}
                          disabled
                          className='block w-full h-20 font-bold text-center border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-xl'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mt-10 mb-5 sm:mt-10 sm:mb-5'>
                  <button
                    type='button'
                    className='inline-flex items-center justify-center w-full px-4 py-2 text-base font-bold text-white bg-red-500 border border-transparent rounded-md shadow-sm hover:bg-gray-200 focus:outline-none h-11 sm:text-sm'
                    onClick={onSubmit}
                  >
                    복사하기
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default JoinClassModal;
