import { Fragment } from 'react';
import { format, register } from 'timeago.js';
import koLocale from 'timeago.js/lib/lang/ko';

export default function Feed({
  feedItem: {
    selectedAnswer,
    imageUrl,
    username,
    stage,
    updatedAt,
    isSolved,
    isCorrect,
    isFault,
  },
}: any) {
  register('ko', koLocale);

  const typeContents: any = {
    image: (
      <img
        src={`${selectedAnswer}`}
        className='w-80 h-60'
        alt='img-mission-answer'
      />
    ),
    text: <p className='max-w-xl'>{selectedAnswer}</p>,
    gps: (
      <img
        src={`${selectedAnswer}`}
        className='w-80 h-60'
        alt='img-mission-answer'
      />
    ),
  };

  return (
    <>
      {/* 피드 프로필 이미지 */}
      <div className='relative'>
        <img
          className='flex items-center justify-center w-10 h-10 bg-gray-400 rounded-full ring-8 ring-white'
          src={imageUrl}
          alt=''
        />
      </div>
      {/* 피드 내용 */}
      <div className='flex-1 min-w-0'>
        <div className='flex'>
          <div className='flex flex-col flex-1'>
            <div className='flex flex-col text-xs xs:text-sm xs:flex-row xs:gap-2'>
              <span className='font-medium text-gray-600'>
                {/* 학생 이름 */}
                {username} 참가자
              </span>
              <strong className='font-medium text-gray-900'>
                {/* 00번 문제 해결완료 */}[{stage}번 문제]
              </strong>
              <span className='font-medium text-gray-600'>해결 완료!</span>
            </div>
            <p className='mt-0.5 text-xs xs:text-sm text-gray-500'>
              {/* 업데이트된 시간 */}
              {format(updatedAt, 'ko')}
            </p>
          </div>
          <div className='flex flex-shrink-0 align-top'>
            <strong>
              {isSolved && isCorrect && isFault
                ? 'PASS'
                : isCorrect && isFault && 'FAIL'}
            </strong>
          </div>
        </div>
        <div className='mt-2 text-sm text-gray-700'>{/* <p>{stage}</p> */}</div>
        <div className='mt-2 text-sm text-gray-700'>{typeContents[`text`]}</div>
      </div>
    </>
  );
}
