const people = [
  {
    name: '김철수',
    code: 'aaaaa',
    score: '2,000',
    image:
      'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: '이철수',
    code: 'bbbbb',
    score: '99,999',
    image:
      'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: '박철수',
    code: 'ccccc',
    score: '11,111',
    image:
      'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: '김철수',
    code: 'aaaaa',
    score: '2,000',
    image:
      'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: '이철수',
    code: 'bbbbb',
    score: '99,999',
    image:
      'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: '박철수',
    code: 'ccccc',
    score: '11,111',
    image:
      'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: '김철수',
    code: 'aaaaa',
    score: '2,000',
    image:
      'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: '이철수',
    code: 'bbbbb',
    score: '99,999',
    image:
      'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: '박철수',
    code: 'ccccc',
    score: '11,111',
    image:
      'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: '김철수',
    code: 'aaaaa',
    score: '2,000',
    image:
      'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: '이철수',
    code: 'bbbbb',
    score: '99,999',
    image:
      'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: '박철수',
    code: 'ccccc',
    score: '11,111',
    image:
      'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
];

export default function Status() {
  return (
    <div className='flex flex-col'>
      <div className='self-center w-full max-w-[1000px] py-10'>
        <h1 className='text-3xl font-bold text-gray-900'>수업 참여</h1>
        <form className='mt-6 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2'>
          <div className='px-4 py-6 sm:p-8'>
            <div className='max-w-2xl space-y-10'>
              <fieldset>
                <legend className='text-sm font-semibold leading-6 text-gray-900'>
                  수업에 참여한 학생 목록입니다.
                </legend>
                <div className='mt-6 space-y-4'>
                  {/* 학생 목록 리스트 */}
                  <ul className='grid grid-cols-1 gap-2 '>
                    {/* 학생 카드 */}
                    {people.map((person) => (
                      // <li className='col-span-1 bg-white divide-y divide-gray-200 rounded-lg shadow'>
                      //   <div className='flex items-center justify-between w-full p-6 space-x-6'>
                      //     <div className='flex-1 truncate'>
                      //       <div className='flex items-center space-x-3'>
                      //         <img
                      //           className='w-10 h-10 rounded-full'
                      //           src={person.image}
                      //           alt=''
                      //         />
                      //         <h3 className='text-sm font-bold text-gray-900 truncate'>
                      //           ABCDE
                      //         </h3>
                      //         <h3 className='text-sm font-bold text-gray-900 truncate'>
                      //           1번
                      //         </h3>
                      //         <h3 className='text-sm font-bold text-gray-900 truncate'>
                      //           김철수
                      //         </h3>
                      //         <span className='flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full'>
                      //           접속중
                      //         </span>
                      //       </div>
                      //     </div>
                      //     {/* 진행상태 토글 */}
                      //   </div>
                      // </li>
                      <li
                        key={person.code}
                        className='col-span-1 bg-white divide-y divide-gray-200 rounded-lg shadow'
                      >
                        <div className='flex items-center justify-between w-full p-4 space-x-6'>
                          <div className='flex-1 truncate'>
                            <div className='flex items-center space-x-3'>
                              <img
                                className='w-10 h-10 rounded-full'
                                src={person.image}
                                alt=''
                              />
                              <div className='flex flex-col'>
                                <div className='flex gap-2'>
                                  <span className='text-sm font-medium text-gray-900'>
                                    {person.name}
                                  </span>
                                  <span className='text-sm text-gray-500'>
                                    {person.code}
                                  </span>
                                </div>
                                <p className='text-sm font-medium text-gray-900'>
                                  {person.score}점
                                </p>
                              </div>
                              <span className='flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full'>
                                접속중
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </fieldset>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
/* 학생 코드 / 학생 이름 / 학생 번호 / 접속 상태 / 진행률 */
