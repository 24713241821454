import React, { useLayoutEffect, useRef, useState } from 'react';
import { studentCodeData } from './_data';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const StudentCodesList = () => {
  const checkbox = useRef<HTMLInputElement | null>(null);
  const [studentCodeChecked, setStudentCodeChecked] = useState(false);
  const [selectedStudentCode, setSelectedStudentCode] = useState([]);
  const [studentCodeIndeterminate, setStudentCodeIndeterminate] =
    useState(false);

  const [limit, setLimit] = useState(10);
  const [studentCodePage, setStudentCodePage] = useState(1);
  const studentCodeOffset = (studentCodePage - 1) * limit;

  useLayoutEffect(() => {
    const isIndeterminateStudent =
      selectedStudentCode.length > 0 &&
      selectedStudentCode.length < studentCodeData.length;
    setStudentCodeChecked(
      selectedStudentCode.length === studentCodeData.length
    );
    setStudentCodeIndeterminate(isIndeterminateStudent);
    checkbox.current.indeterminate = isIndeterminateStudent;
  }, [selectedStudentCode]);

  const studentCodeToggleAll = () => {
    setSelectedStudentCode(
      studentCodeChecked || studentCodeIndeterminate ? [] : studentCodeData
    );
    setStudentCodeChecked(!studentCodeChecked && !studentCodeIndeterminate);
    setStudentCodeIndeterminate(false);
  };

  return (
    <div className='px-6 pt-8 pb-2 sm:px-0'>
      <div className='flow-root'>
        <div className='-mx-6 -my-2 overflow-x-auto lg:-mx-8'>
          <div className='inline-block min-w-full align-middle sm:px-6 lg:px-8'>
            <div className='relative'>
              <table className='min-w-full divide-y divide-gray-300 table-fixed'>
                <thead>
                  <tr>
                    <th
                      scope='col'
                      className='relative w-16 px-8 sm:w-12 sm:px-6'
                    >
                      <input
                        type='checkbox'
                        className='absolute w-4 h-4 -mt-2 text-red-600 border-gray-300 rounded left-6 top-1/2 focus:ring-red-500 sm:left-4'
                        ref={checkbox}
                        checked={studentCodeChecked}
                        onChange={studentCodeToggleAll}
                      />
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pr-3 text-left text-xs sm:text-sm font-semibold text-gray-900 whitespace-nowrap'
                    >
                      코드
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-xs sm:text-sm font-semibold text-gray-900 whitespace-nowrap'
                    >
                      학급
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-xs sm:text-sm font-semibold text-gray-900 whitespace-nowrap'
                    >
                      학생 이름
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-xs sm:text-sm font-semibold text-gray-900 whitespace-nowrap'
                    >
                      접속일
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-xs sm:text-sm font-semibold text-gray-900 whitespace-nowrap'
                    >
                      사용 여부
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 '>
                  {studentCodeData
                    .slice(studentCodeOffset, studentCodeOffset + limit)
                    .map((studentCode, i) => (
                      <tr
                        key={i}
                        className={
                          selectedStudentCode.includes(studentCode)
                            ? 'bg-gray-50'
                            : undefined
                        }
                      >
                        <td className='relative w-16 px-8 sm:w-12 sm:px-6'>
                          {selectedStudentCode.includes(studentCode) && (
                            <div className='absolute inset-y-0 left-0 w-0.5 bg-red-600' />
                          )}
                          <input
                            type='checkbox'
                            className='absolute w-4 h-4 -mt-2 text-red-600 border-gray-300 rounded left-6 top-1/2 focus:ring-red-500 sm:left-4'
                            value={studentCode.studentName}
                            checked={selectedStudentCode.includes(studentCode)}
                            onChange={(e) =>
                              setSelectedStudentCode(
                                e.target.checked
                                  ? [...selectedStudentCode, studentCode]
                                  : selectedStudentCode.filter(
                                      (p) => p !== studentCode
                                    )
                              )
                            }
                          />
                        </td>
                        <td
                          className={classNames(
                            'whitespace-nowrap py-2.5 pr-3 text-xs sm:text-sm font-medium',
                            selectedStudentCode.includes(studentCode)
                              ? 'text-red-600'
                              : 'text-gray-900'
                          )}
                        >
                          {studentCode.code}
                        </td>
                        <td className='px-3 py-2.5 text-xs sm:text-sm text-gray-500 whitespace-nowrap'>
                          {studentCode.className}
                        </td>
                        <td className='px-3 py-2.5 text-xs sm:text-sm text-gray-500 whitespace-nowrap'>
                          {studentCode.studentName}
                        </td>
                        <td className='px-3 py-2.5 text-xs sm:text-sm text-gray-500 whitespace-nowrap'>
                          {studentCode.connectedAt}
                        </td>
                        <td className='px-3 py-2.5 text-xs sm:text-sm text-gray-500 whitespace-nowrap'>
                          <span className='inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full'>
                            {studentCode.state}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StudentCodesList;
