import axios from 'axios';
import { backUrl } from '../config/config';
import { Student } from '../containers/Admin/Student/List/_interface';

axios.defaults.baseURL = backUrl;
axios.defaults.withCredentials = true;

export async function loadStudentListAPI() {
  return await axios.get('students').then((res) => res.data);
}

export async function loadStudentInfoAPI(data: Student) {
  return await axios.get(`students/${data.id}`).then((res) => res.data);
}

export async function createStudentAPI(data: Student) {
  return await axios.post('students', data).then((res) => res.data);
}

export async function updateStudentAPI(data: Student) {
  return await axios
    .patch(`students/admin/student`, data)
    .then((res) => res.data);
}

export async function removeStudentAPI(data: Student) {
  return await axios
    .delete(`students/admin/student/${data.id}`)
    .then((res) => res.data);
}

export async function createStudentByAdmin(data: any) {
  return await axios
    .post(`students/admin/student/${data.classroomId}`, data)
    .then((res) => res.data);
}
