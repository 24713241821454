import ClassHeader from './Header';
import CreateClassModal from './modal';
import ClassList from './List';
import { useSetRecoilState } from 'recoil';
import { teacherDataState } from '../../utils/recoil/data';
import { useQuery } from '@tanstack/react-query';
import Teacher from '../../interfaces/teacher';
import { loadTeacherInfoAPI } from '../../apis/teacher';
export default function Classes() {
  const setTeacherData = useSetRecoilState(teacherDataState);
  useQuery<Teacher>(['teacher'], loadTeacherInfoAPI, {
    onSuccess: (data: Teacher) => {
      setTeacherData(data);
    },
  });

  return (
    <>
      <main className='flex-1'>
        <div className='flex flex-col '>
          <ClassHeader />
          <ClassList />
        </div>
      </main>
      <CreateClassModal />
    </>
  );
}
