import { Dialog, Transition } from '@headlessui/react';
import React, { useEffect, Fragment, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  updateAdminLectureModalState,
  updateStudentModalState,
} from '../../../../utils/recoil/modal';
import Teacher from '../../../../interfaces/teacher';
import Admin from '../../../../interfaces/admin';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  loadAllClassroomByMasterAdminAPI,
  loadAllClassroomWithoutStudentBySlaveAdminAPI,
  loadAllTeacherByAdminAPI,
  loadAllTeacherBySlaveAdminAPI,
} from '../../../../apis/admin';
import Classroom from '../../../../interfaces/classroom';
import { updateLectureState } from '../../../../utils/recoil/data';
import { queryClient } from '../../../..';
import {
  Lecture,
  LectureStatus,
} from '../../../ClassDetail/LectureList/_interface';
import { updateLectureAdminAPI } from '../../../../apis/lecture';
import { useLocation } from 'react-router-dom';

interface Props {
  admin: Admin;
}
const UpdateLectureModal = ({ admin }: Props) => {
  const location = useLocation();
  const query = location.pathname.split('/')[3];
  const { data: teachers } = useQuery<Teacher[]>(
    ['teachers'],
    admin && admin.role === 'Master'
      ? loadAllTeacherByAdminAPI
      : loadAllTeacherBySlaveAdminAPI
  );
  const { data: classrooms } = useQuery<Classroom[]>(
    ['classrooms-teachers'],
    admin && admin.role === 'Master'
      ? loadAllClassroomByMasterAdminAPI
      : loadAllClassroomWithoutStudentBySlaveAdminAPI
  );
  const [updateAdminLectureModal, setUpdateAdminLectureModal] =
    useRecoilState<boolean>(updateAdminLectureModalState);
  const [updateLecture] = useRecoilState<Lecture>(updateLectureState);
  const [count, setCount] = useState(0);
  const [lectureName, setLectureName] = useState('');
  const [lectureInfo, setLectureInfo] = useState('');
  const [lectureStatus, setLectureStatus] = useState(LectureStatus.before);
  const [classroomId, setClassroomId] = useState(+query);
  const [selectedClassroom, setSelectedClassroom] = useState('');
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [first, setFirst] = useState<boolean>(false);

  useEffect(() => {
    if (
      !first &&
      updateLecture.name !== '' &&
      updateLecture.name !== lectureName
    ) {
      setFirst(true);
    }
  }, [first, updateLecture, lectureName]);

  useEffect(() => {
    if (first) {
      setLectureName(updateLecture.name);
      setLectureInfo(updateLecture.info);
      setLectureStatus(updateLecture.status);
    }
  }, [first, updateLecture]);

  const mutation = useMutation({
    mutationFn: updateLectureAdminAPI,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['updateLecture'] });
      alert('수정 성공');
    },
    onError: (err: any) => {
      console.error('error', err);
      alert(`${err.response.data.message}`);
    },
  });
  const closeModal = () => {
    setFirst(false);
    setLectureName('');
    setCount(0);
    setClassroomId(0);
    setUpdateAdminLectureModal(false);
  };
  const handleSelectStatus = (e: any) => {
    setLectureStatus(e.target.value);
  };
  const handleSelectClassroom = (e: any) => {
    const selectedClassroom = classrooms?.find(
      (classroom) => classroom.name === e.target.value
    );
    if (selectedClassroom) {
      setClassroomId(selectedClassroom.id);
      setSelectedClassroom(selectedClassroom.name);
    }
    const selectedTeacher = teachers?.find(
      (teacher) => teacher.id === selectedClassroom.teacherId
    );
    if (selectedTeacher) {
      setSelectedTeacher(selectedTeacher.name);
    }
  };
  const onSubmit = () => {
    mutation.mutate({
      id: updateLecture.id,
      classroomId,
      name: lectureName,
      info: lectureInfo,
      status: lectureStatus,
    });
    closeModal();
  };
  return (
    <Transition.Root show={updateAdminLectureModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-opacity-75 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  {/* <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                  <CheckIcon
                    className="w-6 h-6 text-green-600"
                    aria-hidden="true"
                  />
                </div> */}
                  {/* <div className="flex flex-col flex-1 p-8"></div> */}
                  <div className="mt-5 text-center sm:mt-5">
                    <Dialog.Title
                      as="h1"
                      className="pb-4 text-2xl font-bold leading-6 text-gray-900"
                    >
                      수업 수정
                    </Dialog.Title>
                    {/* <Dialog.Description
                      as='p'
                      className='pt-4 leading-6 text-gray-700 text-'
                    >
                      1 / 3
                    </Dialog.Description> */}
                    <div className="mt-2">
                      <div className="relative flex mt-2 rounded-md shadow-sm sm:mt-4">
                        <input
                          type="text"
                          id="count"
                          name="count"
                          placeholder="수업명을 입력해주세요"
                          value={lectureName}
                          onChange={(e) => setLectureName(e.target.value)}
                          autoComplete="off"
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-5 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                        />
                      </div>
                      <div className="relative flex mt-2 rounded-md shadow-sm sm:mt-4">
                        <input
                          type="text"
                          id="lectureInfo"
                          name="lectureInfo"
                          placeholder="수업정보를 입력해주세요"
                          value={lectureInfo}
                          onChange={(e) => setLectureInfo(e.target.value)}
                          autoComplete="off"
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-5 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                        />
                      </div>
                      <div className="relative flex mt-2 rounded-md shadow-sm sm:mt-4">
                        <select
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-5 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                          onChange={handleSelectStatus}
                          value={lectureStatus}
                        >
                          <option
                            value=""
                            disabled
                            className="font-bold disabled:text-gray-500"
                          >
                            진행상태
                          </option>
                          <option value={'Before'}>진행전</option>
                          <option value={'Ing'}>진행중</option>
                          <option value={'After'}>진행완료</option>
                        </select>
                      </div>
                      <div className="relative flex mt-2 rounded-md shadow-sm sm:mt-4">
                        <select
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-5 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                          onChange={handleSelectClassroom}
                          value={selectedClassroom}
                        >
                          <option
                            value=""
                            disabled
                            className="font-bold disabled:text-gray-500"
                          >
                            학급 선택
                          </option>
                          {classrooms &&
                            classrooms.map((classroom) => (
                              <option
                                key={classroom.name + classroom.id}
                                value={classroom.name}
                              >
                                {classroom.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="relative flex mt-2 rounded-md shadow-sm sm:mt-4">
                        <input
                          type="text"
                          id="teacherId"
                          name="teacherId"
                          placeholder="담당 선생님을 입력해주세요"
                          readOnly={true}
                          value={selectedTeacher}
                          autoComplete="off"
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-5 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10 mb-5 sm:mt-10 sm:mb-5">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-bold text-white bg-red-500 border border-transparent rounded-md shadow-sm hover:bg-gray-200 focus:outline-none h-11 sm:text-sm"
                    onClick={onSubmit}
                  >
                    저장하기
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UpdateLectureModal;
