import React from 'react';
import TeacherHeader from './Header';
import TeacherList from './List';
import UpdateTeacherModal from './Modal/updateTeacher';
import CreateTeacherModal from './Modal/createTeacher';
import { useQuery } from '@tanstack/react-query';
import Admin from '../../../interfaces/admin';
import { loadAdminInfoAPI } from '../../../apis/admin';
const AdminTeacher = () => {
  const { isLoading, data: admin } = useQuery<Admin>(
    ['admin'],
    loadAdminInfoAPI
  );
  return (
    <>
      <main className="flex-1">
        <div className="flex flex-col ">
          <TeacherHeader admin={admin}/>
          <TeacherList admin={admin} />
        </div>
      </main>
      <CreateTeacherModal />
      <UpdateTeacherModal />
    </>
  );
};

export default AdminTeacher;
