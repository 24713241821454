import React from 'react';
import AdminClasses from '../../../containers/Admin/Classes';

export default function AdminClassesPage() {
  return (
    <>
      <AdminClasses />
    </>
  );
}
