import React, { useEffect } from 'react';
import AccountHeader from './Header';
import AccountList from './List';
import CreateAdminModal from './Modal/createAdmin';
import UpdateAdminModal from './Modal/updateAdmin';
import Admin from '../../../interfaces/admin';
import { loadAdminInfoAPI, loadAllAdminsAPI } from '../../../apis/admin';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

const AdminAccount = () => {
  const { isLoading, data: admin } = useQuery<Admin>(
    ['admin'],
    loadAdminInfoAPI
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading && admin && admin.role === 'Slave') {
      alert('권한이 없습니다.');
      return navigate('/admin/teacher');
    }
  }, [isLoading, admin]);

  return (
    <>
      <main className="flex-1">
        <div className="flex flex-col ">
          <AccountHeader admin={admin} />
          <AccountList admin={admin} />
        </div>
      </main>
      <CreateAdminModal />
      <UpdateAdminModal />
    </>
  );
};

export default AdminAccount;
