import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { useRecoilState } from 'recoil';
import { createClassModalState } from '../../utils/recoil/modal';
import { useMutation } from '@tanstack/react-query';
import { createClassroomAPI } from '../../apis/classroom';
import { queryClient } from '../..';
const CreateClassModal = () => {
  const [className, setClassName] = useState(''); // 학급 이름을 저장하는 state
  const [createClassModal, setCreateClassModal] = useRecoilState<boolean>(
    createClassModalState
  );

  const mutation = useMutation({
    mutationFn: createClassroomAPI,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['createClassroom'] });
      alert('생성 성공');
    },
    onError: (err) => {
      console.error('error', err);
      alert('생성 실패');
    },
  });
  const closeModal = () => {
    setClassName(''); // 학급 이름 state 초기화
    setCreateClassModal(false);
  };

  const onSubmit = () => {
    const confirm = window.confirm('학급을 생성하시겠습니까?');
    if (!confirm) return;
    mutation.mutate({ name: className });
    closeModal();
  };

  return (
    <Transition.Root show={createClassModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-opacity-75 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  {/* <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                  <CheckIcon
                    className="w-6 h-6 text-green-600"
                    aria-hidden="true"
                  />
                </div> */}
                  {/* <div className="flex flex-col flex-1 p-8"></div> */}
                  <div className="mt-1 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-bold leading-6 text-gray-900"
                    >
                      학급 생성하기
                    </Dialog.Title>
                    <div className="mt-4">
                      <label className="text-lg font-bold text-gray-700 ">
                        학급 이름
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm sm:mt-4">
                        <input
                          type="text"
                          value={className}
                          onChange={(e) => setClassName(e.target.value)}
                          className="block w-full h-12 font-bold border-gray-300 py-1.5 pr-10 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-xl"
                        />
                        {/* <div className='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'>
                        <ExclamationCircleIcon
                          className='w-5 h-5 text-red-500'
                          aria-hidden='true'
                        />
                      </div> */}
                      </div>
                      {/* <p
                      className='mt-2 text-sm text-red-600'
                      id='text-error'
                    >
                      학급 이름을 올바르게 입력해주세요.
                    </p> */}
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 mt-5 sm:mt-10 sm:mb-5">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-bold text-white bg-gray-400 border border-transparent rounded-md shadow-sm hover:bg-gray-200 focus:outline-none h-11 sm:text-sm"
                    onClick={closeModal}
                  >
                    취소
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-bold text-white bg-red-500 border border-transparent rounded-md shadow-sm hover:bg-gray-200 focus:outline-none h-11 sm:text-sm"
                    onClick={onSubmit}
                  >
                    만들기
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateClassModal;
