import { Manager } from 'socket.io-client';

export const backUrl =
  process.env.NODE_ENV === 'production'
    ? 'http://cms-be-ALB-1-1507536956.ap-northeast-2.elb.amazonaws.com' // cms-be-로드벨런서 DNS 이름
    : 'http://localhost:3065'; // 로컬 백앤드
// : 'http://cms-be-ALB-1-1507536956.ap-northeast-2.elb.amazonaws.com'; // 로드벨런서 백앤드.

// export const backContentUrl =
//   process.env.NODE_ENV === 'production'
//     ? 'http://content-back.earth17plus.com'
//     : 'http://localhost:3085';

const manager =
  process.env.NODE_ENV === 'production'
    ? new Manager(
        'http://cms-socket-ALB-1-1423547743.ap-northeast-2.elb.amazonaws.com'
      )
    : new Manager('http://localhost:3055');
// :  new Manager('http://172.30.1.65:3055');

export const socket = manager.socket('/');
