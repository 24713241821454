import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { updateAdminModalState } from '../../../../utils/recoil/modal';
import { accountData } from './_data';
import { loadAllAdminsAPI, removeMasterAdminAPI } from '../../../../apis/admin';
import Admin from '../../../../interfaces/admin';
import { useQuery, useMutation } from '@tanstack/react-query';
import { queryClient } from '../../../..';
import { updateAdminState } from '../../../../utils/recoil/data';

interface Props {
  admin: Admin;
}

export default function AccountTable({ admin }: Props) {
  const { data: admins } = useQuery<Admin[]>(['admins'], loadAllAdminsAPI);
  const deleteMutation = useMutation({
    mutationFn: removeMasterAdminAPI,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['deleteAdmin'] });
      alert('삭제 성공');
    },
    onError: (err) => {
      console.error('error', err);
      alert('삭제 실패');
    },
  });
  const setUpdateAdminModal = useSetRecoilState<boolean>(updateAdminModalState);
  const setUpdateAdminState = useSetRecoilState<Admin>(updateAdminState);

  const handle = {
    update: (e: any) => {
      setUpdateAdminState({
        id: admins[e].id,
        account: admins[e].account,
        role: admins[e].role,
        active: admins[e].active,
      });
      setUpdateAdminModal(true);
    },
    delete: (id: any) => {
      deleteMutation.mutate({ id });
    },
  };

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
          >
            <Link to="#" className="inline-flex group">
              No
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              아이디
              <span className="flex-none ml-2 text-gray-900 bg-gray-100 rounded group-hover:bg-gray-200">
                <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              이름
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              관리자 유형
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              활성화 여부
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              생성일
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <span className="inline-flex group">
              수정
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </span>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <span className="inline-flex group">
              삭제
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {admins &&
          admins.map((account, i) => (
            <tr key={account.id}>
              <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">
                {i + 1}
              </td>
              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                {account.account}
              </td>
              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                {account.name}
              </td>
              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                {account.role}
              </td>
              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                {account.active ? 'YES' : 'NO'}
              </td>
              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                {account.connection_date.toString().split('T')[0]}
              </td>
              <td className="relative py-4 pl-3 pr-4 text-sm text-left whitespace-nowrap sm:pr-0">
                <button
                  className="text-red-600 hover:text-red-900"
                  onClick={() => handle.update(i)}
                >
                  수정<span className="sr-only">, {account.id}</span>
                </button>
              </td>
              <td className="relative py-4 pl-3 pr-4 text-sm text-left whitespace-nowrap sm:pr-0">
                {account.role !== 'Master' && (
                  <button
                    className="text-red-600 hover:text-red-900"
                    onClick={() => handle.delete(account.id)}
                  >
                    삭제<span className="sr-only">, {account.id}</span>
                  </button>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
