import { useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { selectedClassTabState } from '../../utils/recoil/tab';
import ClassDetailHeader from './Header';
import JoinClassModal from './Modal/joinClassModal';
import ClassDetailTab from './Tab';
import StudentList from './StudentList';
import LectureList from './LectureList';
import CreateLectureModal from './Modal/createLectureModal';
import JoinLectureModal from './Modal/joinLecture';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Classroom from '../../interfaces/classroom';
import { loadClassroomInfoAPI } from '../../apis/classroom';
import JoinLectureLinkModal from './Modal/joinLectureLink';
import { classroomDataState } from '../../utils/recoil/data';

export default function ClassDetail() {
  const selected = useRecoilValue<string>(selectedClassTabState);
  const location = useLocation();
  const path = parseInt(location.pathname.split('/')[2]);

  const setClassroomData = useSetRecoilState(classroomDataState);
  const { data: classroom } = useQuery<Classroom>(
    ['classroom', path],
    () => loadClassroomInfoAPI({ id: path }),
    {
      onSuccess: (data: Classroom) => {
        setClassroomData(data);
      },
    }
  );

  const renderList = useMemo(() => {
    if (classroom)
      return selected === 'studentList' ? (
        <StudentList students={classroom.students} />
      ) : (
        <LectureList classroomId={classroom.id} />
      );
  }, [selected, classroom]);

  return (
    <>
      <main className='flex-1'>
        <div className='flex flex-col '>
          <ClassDetailHeader />
          <ClassDetailTab />
          {renderList}
        </div>
      </main>
      <JoinClassModal />
      <CreateLectureModal />
      <JoinLectureModal />
      <JoinLectureLinkModal />
    </>
  );
}
