import React from 'react';
import { Outlet } from 'react-router-dom';

const AuthLayout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default AuthLayout;

/* 
  모듈화!!!
  1. 어스
      로그인 / 회원가입

  2. 선생님
      학급
      학생
      코드
      수업

  3. 어드민
      학급
      학생
      코드
      수업

      미래기업가정신

                  
      (어드민 어스), (선생님 어스), (콘텐츠 2개 어스) 인스턴스 총 4개

      콘텐츠 API > 미래기업가정신 / 어스17
      학생 코드기준 > 학급코드 입력 > 어스17 > 팀별
      미래기업가정신 > 개인별

      어드민 코드관리 > 미래기업가정신 코드 어스17 코드 함께 관리가 가능한가? > 코드만 리스트화 해서 만들고 지우고 하면된다.
      어드민 코드만들면 > 미래기업 RDS / 어스17 RDS 나눠서 코드가 들어감

      코드 > 권한 콘텐츠명

  3. 콘텐츠
      콘텐츠 로직

      학생코드 > 학급 코드 > 번호 이름 비밀번호 > 학생등록 완료         => 어디 저장?? DB 저장 선생님페이지에 연결된 DB


  EKS (비용 증가!)
    Auto scaling
    Load balance


    1,2,3,4,5

    어스 / 선생님 / 콘텐츠 어떻게 ??

    RDS - DB 하나로 연결
    id값을 등록한다.
    user table  / contents table / stage table
    user id 값을 stage table에 추가해서 매칭한 후에 가져온다. 

    user id 못찾아서가 아니라 token 권한을 못찾아서가 문제 아닌가??

    user id 로 user 정보를 가져옴.
    user table      contents table            stage table
      id : 1           userId : 1                userId: 1

      userId 로 contents get / stage get

      user id값을 정하고 - contetn table n:n 

    Auto load => token 값을 잘 주고받아야 함.
    pc1 받은 토큰
    pc2 못받은 토큰

    pc1-1 pc1-2
    pc2-1 pc2-2
    pc3-1 pc3-2

    pc1 > sub-node 10개 > header에 항상 검사 > DB 백서버 스토리지에 넣어놔서 

    백서버 복제 > 스토리지도 복제 > 스토리지 10개가 생겨서 > 적용 X

    백서버1-1, 1-2, 2-1, 2-2, 3-1, 3-2 (auto load) - API Gateway - 프서버1-1, 1-2, 2-1, 2-2, 3-1, 3-2 (auto load)
    권한 - 헤더에서 토큰 검증 항상해줬음 (비효율적)
    RDS > user table <= (access token / fresh token)

    로그인 > token > RDS userToken > 입력완료
    백서버1-1 > userToken user 정보와 userToken 정보를 매칭 

    세션 token / user API get 정보 비교
    RDS userToken / user API 비교
    보안상 RDS 2개 파면됨.
    token용 RDS / userAPI용 RDS
    보안처리 접근막는.

    웹브라우저 저장소
      token / userid 정보 비교

      api -> 백서버 token 헤더가 백서비 백에서도 검증(가드) 프론트 검증 (session / cookie)
*/
