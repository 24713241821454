import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { updateStudentModalState } from '../../../../utils/recoil/modal';
import { loadAllClassroomByMasterAdminAPI } from '../../../../apis/admin';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Student } from './_interface';
import Admin from '../../../../interfaces/admin';
import { updateStudentState } from '../../../../utils/recoil/data';
import { removeStudentAPI } from '../../../../apis/student';
import { queryClient } from '../../../..';

interface Props {
  admin: Admin;
}

export default function Table({ admin }: Props) {
  const { data: classrooms } = useQuery(
    ['classrooms'],
    loadAllClassroomByMasterAdminAPI
  );
  const setUpdateStudentModal = useSetRecoilState<boolean>(
    updateStudentModalState
  );
  const setUpdateStudent = useSetRecoilState<Student>(updateStudentState);
  const deleteMutation = useMutation({
    mutationFn: removeStudentAPI,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['deleteStudent'] });
      alert('삭제 성공');
    },
    onError: (err) => {
      console.error('error', err);
      alert('삭제 실패');
    },
  });
  const handle = {
    update: (student: any, teacher: any) => {
      setUpdateStudent({
        id: student.id,
        name: student.name,
        num: student.num,
        teacher,
      });
      setUpdateStudentModal(true);
    },
    delete: (id: any) => {
      const confirm = window.confirm('삭제하시겠습니까?');
      if (!confirm) return;
      deleteMutation.mutate({ id });
    },
  };
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
          >
            <Link to="#" className="inline-flex group">
              No
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              학생 이름
              <span className="flex-none ml-2 text-gray-900 bg-gray-100 rounded group-hover:bg-gray-200">
                <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              학생 코드
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              소속 학급
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              담당 선생님
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              담당 관리자
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              생성 시간
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <Link to="#" className="inline-flex group">
              사용 여부
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </Link>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <span className="inline-flex group">
              수정
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </span>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <span className="inline-flex group">
              삭제
              <span className="flex-none invisible ml-2 text-gray-400 rounded group-hover:visible group-focus:visible">
                <ChevronDownIcon
                  className="flex-none invisible w-5 h-5 ml-2 text-gray-400 rounded group-hover:visible group-focus:visible"
                  aria-hidden="true"
                />
              </span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {classrooms &&
          classrooms.map((classroom: any) => {
            return classroom.students.map((student: Student, i: number) => {
              return (
                <tr key={student.name + i}>
                  <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">
                    {i + 1}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                    {student.name}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                    {student.code}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                    {classroom.name}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                    {classroom.teacher.name}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                    {admin && admin.name}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                    {student.createdAt.toString().split('T')[0]}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                    {student.isConnected ? 'YES' : 'NO'}
                  </td>
                  <td className="relative py-4 pl-3 pr-4 text-sm text-left whitespace-nowrap sm:pr-0">
                    <button
                      className="text-red-600 hover:text-red-900"
                      onClick={() => handle.update(student, classroom.teacher)}
                    >
                      수정<span className="sr-only">, {student.id}</span>
                    </button>
                  </td>
                  <td className="relative py-4 pl-3 pr-4 text-sm text-left whitespace-nowrap sm:pr-0">
                    <button
                      className="text-red-600 hover:text-red-900"
                      onClick={() => handle.delete(student.id)}
                    >
                      삭제<span className="sr-only">, {student.id}</span>
                    </button>
                  </td>
                </tr>
              );
            });
          })}
      </tbody>
    </table>
  );
}
