import React from 'react';
import CodeHeader from './Header';
import CodeList from './List';
import CreateCodeModal from './Modal/createCode';
import UpdateCodeModal from './Modal/updateCode';
import Admin from '../../../interfaces/admin';
import { useQuery } from '@tanstack/react-query';
import { loadAdminInfoAPI } from '../../../apis/admin';

const AdminClassCode = () => {
  const { data: admin } = useQuery<Admin>(['admin'], loadAdminInfoAPI);
  return (
    <>
      <main className="flex-1">
        <div className="flex flex-col ">
          <CodeHeader admin={admin} />
          <CodeList admin={admin} />
        </div>
      </main>
      <CreateCodeModal />
      <UpdateCodeModal />
    </>
  );
};

export default AdminClassCode;
