import useInput from '../../../../utils/hooks/useInput';

export default function NumberInput({
  name,
  placeholder,
  disabled = false,
}: any) {
  const [value, onChangeValue] = useInput(7);

  return (
    <div>
      <label
        htmlFor='finish-round'
        className='block text-sm font-medium leading-6 text-gray-900'
      >
        {name}
      </label>
      <div className='relative mt-2 rounded-md shadow-sm'>
        <input
          type='text'
          name='finish-round'
          id='finish-round'
          className='block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          placeholder={`${placeholder}`}
          value={value}
          onChange={onChangeValue}
          disabled={disabled}
        />
        {/* <div className='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'>
          <QuestionMarkCircleIcon
            className='w-5 h-5 text-gray-400'
            aria-hidden='true'
          />
        </div> */}
      </div>
    </div>
  );
}
